import { Avatar } from "@material-ui/core";
import React, {ChangeEvent, useRef} from "react";
import { useState } from "react";
import { selectToken, selectUserInfo, updateUserInfo } from "../../../features/account/accountSlice";
import { useAbortController, useAppDispatch, useAppSelector } from "../../../hooks";
import { Button } from "../../Widgets/Button/Button";
import { InputBox } from "../../Widgets/InputBox/InputBox";
import './Profile.css';
import * as ApiHelper from "../../../api/ApiHelper";
import { UserInfo } from "../../../models/UserInfo";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import Loader from "../../Widgets/Loader/Loader";
import { TextBox } from "../../Widgets/InputBox/TextBox";
import { Breadcrumbs } from "../../Widgets/Breadcrumbs/Breadcrumbs";
import { DialogChangePassword } from "../../Widgets/DialogChangePassword/DialogChangePassword";
import {useTranslation} from "react-i18next";
import {DialogChangeAvatar} from "../../Widgets/DialogChangeAvatar/DialogChangeAvatar";
import {getUserPhotoUrl} from "../../../api/ApiHelper";

function Profile() {
    const uploadFileInput = useRef<HTMLInputElement>(null);
    const [uploadImage, setUploadImage] = useState(null as string | null);
    const {t} = useTranslation();
    const controller = useAbortController();
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const token = useAppSelector(selectToken);
    const [name, setName] = useState(userInfo?.name ?? "");
    const [clinic, setClinic] = useState(userInfo?.clinic ?? "");
    const [clinicDetails, setClinicDetails] = useState(userInfo?.clinicDetails ?? "");
    const [info, setInfo] = useState(userInfo?.info ?? "");
    const [errorMessage, setErrorMessage] = useState("");
    const [badName, setBadName] = useState(name.trim() === "");
    const [isFetching, setFetchingState] = useState(false);
    const [showPasswordChangeDialog, setShowPasswordChangeDialog] = useState(false);
    const handleUploadClick = () => {
        uploadFileInput.current?.click();
    };
    const onSelectFile = (e : ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUploadImage(reader.result as string));
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const handleChangePasswordClick = () => {
        setShowPasswordChangeDialog(true);
    };
    const handleUpdateSuccess = (ui: UserInfo) => {
        dispatch(updateUserInfo(ui));
        setFetchingState(false);
    }
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            setErrorMessage(t("connection_problem"));
        }
    };
    const handleSaveClick = () => {
        if (name.trim() === "") {
            setBadName(true);
            setErrorMessage(t("enter_name"));
        } else {
            setBadName(false);
            setErrorMessage("");
            if (token) {
                setFetchingState(true);
                ApiHelper.updateProfile(token.token, name, clinic, clinicDetails, info, controller, handleUpdateSuccess, errorHandler);
            }
        }
    };
    if (isFetching) {
        return (
            <Loader />
        );
    }
    const photoUrl = getUserPhotoUrl(userInfo?.photoUrl);
    return (
        <div>
            <Breadcrumbs />
            <div className="d-flex justify-content-center">
                <div className="window-modal">
                    <div className="profile-title disable-select">{t("personal_data")}</div>
                    <div className="profile-controls">
                        <Avatar className="profile-avatar" src={photoUrl ?? ""} alt={userInfo?.name ?? ""} />
                        <div className="profile-buttons">
                            <div className="profile-button left disable-select" onClick={handleUploadClick}>{t("upload_photo")}</div>
                            <div className="profile-button right disable-select" onClick={handleChangePasswordClick}>{t("change_password")}</div>
                        </div>
                    </div>
                    <div className="profile-error d-flex justify-content-center my-2">{errorMessage}</div>
                    <InputBox className="mb-4" inputType="text" initialValue={name} title={t("name")} onChange={setName} hasError={badName} />
                    <InputBox className="mb-4" inputType="text" initialValue={clinic} title={t("clinic")} onChange={setClinic} />
                    <TextBox className="mb-4 input-box-textarea" initialValue={clinicDetails} title={t("clinic_contact_info")} onChange={setClinicDetails} />
                    <TextBox className="mb-4 input-box-textarea" initialValue={info} title={t("additional_info")} onChange={setInfo} />
                    <div className="profile-save-button-container">
                        <Button className="profile-save-button" text={t("save")} onClick={handleSaveClick} highlighted={true} />
                    </div>
                </div>
            </div>
            {showPasswordChangeDialog && <DialogChangePassword token={token?.token ?? ""} controller={controller} cancelButtonClickHandler={() => setShowPasswordChangeDialog(false)} />}
            {uploadImage && <DialogChangeAvatar token={token?.token ?? ""} controller={controller} cancelButtonClickHandler={() => setUploadImage(null)} src={uploadImage}/>}
            <input type="file" accept="image/*" onChange={onSelectFile} ref={uploadFileInput} style={{display: "none"}}/>
        </div>
    );
}

export default Profile;