export const Unknown = 0;
export const BadCredentials = 101;
export const EmailAlreadyInUse = 102;
export const BadPassword = 103;
export const BadEmail = 104;
export const BadUserName = 105;
export const TokenRejected = 110;
export const EmailNotConfirmed = 111;
export const UserBlocked = 112;
export const UserNotFound = 120;
export const Forbidden = 403;
export const NotFound = 404;