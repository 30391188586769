const HISTORY_SIZE = 8;
const A0 = 0.99833;
const A1 = 1 - A0;

export function filterEcgData(data: Array<number | null>, lpf: boolean, hpf: boolean, from: number = 0, count: number = data.length - from): Array<number | null> {
    const out = new Array<number | null>();
    const history: number[] = [];
    let lowPass = 0;
    let average = 0;
    const start = Math.max(0, from - HISTORY_SIZE);
    let delay = from - start;
    for (let i = from; i < from + count; i++) {
        let output = data[i];
        history.push(output ?? 0);
        const historyValue = history.length > HISTORY_SIZE ? history.shift() ?? 0 : 0;
        lowPass = lowPass + (output ?? 0) - historyValue;
        average = A0 * average + A1 * (output ?? 0);
        if (output) {
            if (lpf) {
                output = lowPass / HISTORY_SIZE;
            }
            if (hpf) {
                output = output - Math.round(average);
            }
        }
        delay--;
        if (delay < 0) {
            out.push(output);
        }
    }
    return out;
}