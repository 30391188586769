import React from 'react';
import * as ApiHelper from '../../../api/ApiHelper';
import { useEffect, useState } from 'react';
import { selectToken } from '../../../features/account/accountSlice';
import handleErrors from '../../../helpers/ErrorHandler';
import { useAbortController, useAppDispatch, useAppSelector } from '../../../hooks';
import ErrorResponse from '../../../models/ErrorResponse';
import PagedData from '../../../models/PagedData';
import { SORT_BY_NAME } from '../../../models/SortType';
import { User } from '../../../models/User';
import AsyncIndicator from '../AsyncIndicator/AsyncIndicator';
import { FetchError } from '../FetchError/FetchError';
import { ListPagination } from '../Pagination/ListPagination';
import { SearchInput } from '../SearchInput/SearchInput';
import UsersLong from '../UsersLong/UsersLong';
import UsersPlaceholder from '../UsersPlaceholder/UsersPlaceholder';
import {useTranslation} from "react-i18next";

interface UsersListProps {
    showTitle?: boolean;
    pageSize: number;
    userClickHandler: (id: string) => void;
}


export const UsersList: React.FC<UsersListProps> = ({ showTitle = false, pageSize, userClickHandler }: UsersListProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const controller = useAbortController();
    const token = useAppSelector(selectToken);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filter, setFilter] = useState("");
    const [users, setUsers] = useState([] as User[]);
    const handleFilterChange = (filter: string) => {
        setFilter(filter);
        fetchUsers(currentPage, filter);
    };
    const paginationClickHandler = (page: number) => {
        setFetchingState(true);
        setCurrentPage(page);
    };
    const usersHandler = (pagedData: PagedData<User>) => {
        setFetchingState(false);
        setErrorState(false);
        setUsers(pagedData.data);
        setCurrentPage(pagedData.page);
        setTotalPages(pagedData.totalPages);
        setTotalRecords(pagedData.totalRecords);
    };
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
        }
    };
    const fetchUsers = (page: number, filter: string) => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getUsers(userToken, filter !== "" ? filter : null, SORT_BY_NAME, page, pageSize, controller, usersHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchUsers(currentPage, filter), [currentPage, filter]);     // eslint-disable-line
    if (isFetching) {
        return (
            <AsyncIndicator />
        );
    }
    if (hasError) {
        return (
            <FetchError onRetry={() => fetchUsers(currentPage, filter)} />
        );
    }
    const userComponents = users.map(user => <UsersLong key={user.id} user={user} onClick={() => userClickHandler(user.id)} />)
    const hasData = userComponents.length > 0;
    return (
        <div className="data-list-container">
            <div className="data-list">
                {(hasData && showTitle) &&
                    <div className="data-list-title">{t("total_users")}: <span>{totalRecords}</span></div>
                }
                {hasData && <SearchInput text={filter} onChange={handleFilterChange} />}
                {hasData ? (
                    <table>
                        <thead>
                            <tr className="table-header">
                                <th>{t("email")}</th>
                                <th>{t("role")}</th>
                                <th>{t("name")}</th>
                                <th>{t("registration_date")}</th>
                                <th>{t("email_status")}</th>
                                <th>{t("account_status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userComponents}
                        </tbody>
                    </table>
                ) : (
                    <UsersPlaceholder className="my-4" />
                )}
            </div>
            {totalPages > 1 && <ListPagination page={currentPage} totalPages={totalPages} clickHandler={paginationClickHandler} />}
        </div >
    );
}