import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {selectUserInfo} from "../../../features/account/accountSlice";
import {useAppSelector} from "../../../hooks";
import {MY_RECORDS_ROUTE} from "../../../routes";
import {DATA_LIST_PAGE_SIZE} from "../../../settings";
import {RecordsList} from "../../Widgets/RecordsList/RecordsList";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {ControlledMenu, MenuItem, useMenuState} from "@szhsin/react-menu";
import {useTranslation} from "react-i18next";
import '@szhsin/react-menu/dist/index.css';
import {AccessType} from "../../../models/AccessType";

function MyRecords() {
    const {t} = useTranslation();
    const history = useHistory();
    const userInfo = useAppSelector(selectUserInfo);
    const {toggleMenu, ...menuProps} = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
    const [shareRecordId, setShareRecordId] = useState(null as string | null);
    const recordClickHandler = (id: string) => {
        history.push(`${MY_RECORDS_ROUTE}/${id}`);
    };
    const contextMenuHandler = (id: string, x: number, y: number) => {
        setAnchorPoint({x: x, y: y});
        setShareRecordId(id);
        toggleMenu(true);
    };
    const shareClickHandler = () => {
        history.push(`${MY_RECORDS_ROUTE}/${shareRecordId}/share`);
    };
    return (
        <div>
            <Breadcrumbs/>
            <RecordsList pageSize={DATA_LIST_PAGE_SIZE} userId={userInfo?.id ?? null} accessType={AccessType.Owner}
                         patientId={null}
                         recordClickHandler={recordClickHandler} contextMenuHandler={contextMenuHandler}/>
            <ControlledMenu {...menuProps} anchorPoint={anchorPoint}
                            onClose={() => toggleMenu(false)} menuClassName={"context-menu"}>
                <MenuItem onClick={shareClickHandler}>{t("access_control")}</MenuItem>
            </ControlledMenu>
        </div>
    );
}

export default MyRecords;