import React, {useRef, useState} from "react";
import SvgClearIcon from "../../../assets/svg/ClearIcon";
import SvgSearchIcon from "../../../assets/svg/SearchIcon";
import './SearchInput.css';
import {useTranslation} from "react-i18next";

interface SearchInputProps {
    text: string;
    onChange: (v: string) => void;
    className?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({ text, onChange, className = "" }: SearchInputProps) => {
    const {t} = useTranslation();
    const [hasFocus, setFocus] = useState(false);
    const [filter, setFilter] = useState(text);
    const [previousFilter, setPreviousFilter] = useState(text);
    const [timerId, setTimerId] = useState(null as NodeJS.Timer | null);
    const input = useRef<HTMLInputElement>(null);
    const handleInputKeyDown = (e: React.KeyboardEvent) => {
        const keyCode = e.key;
        if (keyCode === "Enter") {
            if (timerId){
                clearTimeout(timerId);
            }
            input.current?.blur();
        }
    };
    const handleContainerClick = () => {
        input.current?.focus();
    };
    const handleInputFocus = () => {
        setFocus(true);
    };
    const handleInputBlur = () => {
        setFocus(false);
        updateFilter(filter);
    };
    const updateFilter = (value : string) => {
        if (previousFilter !== value) {
            onChange(value);
            setPreviousFilter(value);
        }
    };
    const handleClearClick = (e : React.MouseEvent) => {
        if (hasFocus && input.current) {
            input.current.value = "";
            handleChange("");
            e.preventDefault();
        }
    };
    const handleChange = (value: string) => {
        setFilter(value);
        const timerId = setTimeout(() => updateFilter(value), 300);
        setTimerId(timerId);
    };
    return (
        <div className={`search-input-container ${className}`} onClick={handleContainerClick}>
            <input type="text" placeholder={t("search")} onFocus={handleInputFocus} onBlur={handleInputBlur} onKeyDown={handleInputKeyDown} defaultValue={text} ref={input} onChange={e => handleChange(e.target.value)} />
            <div className={`search-input-icon ${hasFocus ? "search-input-icon-clear" : ""}`} onMouseDown={handleClearClick}>
                {hasFocus ? <SvgClearIcon /> : <SvgSearchIcon />}
            </div>
        </div>
    );
}