import React from "react";

function SvgLockIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 11 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.045 6.143V4.429A2.29 2.29 0 015.33 2.143a2.29 2.29 0 012.286 2.286v1.714H3.045zM10.473 7a.857.857 0 00-.857-.857H9.33V4.429c0-2.197-1.803-4-4-4-2.196 0-4 1.803-4 4v1.714h-.285A.857.857 0 00.188 7v5.143c0 .473.383.857.857.857h8.571a.857.857 0 00.857-.857V7z"
                fill="currentColor"
            />
        </svg>
    );
}
export default SvgLockIcon;
