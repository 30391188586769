export interface PlotPoint {
    x: number;
    y: number;
}

export class PlotPointsBuilder{

    private readonly points : Array<PlotPoint>;

    private first : PlotPoint | null;
    private min : PlotPoint | null;
    private max : PlotPoint | null;
    private last : PlotPoint | null;

    private previousPoint : PlotPoint | null;

    constructor() {
        this.points = new Array<PlotPoint>();
        this.first = null;
        this.min = null;
        this.max = null;
        this.last = null;
        this.previousPoint = null;
    }

    addData(x : number, y : number){
        let point = {
            x: x,
            y: y
        };
        if (this.previousPoint && this.first && this.min && this.max){
            if (Math.floor(this.previousPoint.x) === Math.floor(x)){
                if (y > this.max.y){
                    this.max = point;
                }
                if (y < this.min.y){
                    this.min = point;
                }
            } else {
                this.last = this.previousPoint;
                this.saveSegment();
                this.initSegment(point);
            }
            this.previousPoint = point;
        } else {
            this.previousPoint = point;
            this.initSegment(point);
        }
    }

    private initSegment(p : PlotPoint){
        this.first = p;
        this.min = p;
        this.max = p;
    }

    private saveSegment(){
        if (this.first && this.min && this.max && this.last){
            this.points.push(this.first);
            let second : PlotPoint;
            let third : PlotPoint;
            if (this.min.x < this.max.x){
                second = this.min;
                third = this.max;
            } else {
                second = this.max;
                third = this.min;
            }
            if (second !== this.first){
                this.points.push(second);
            }
            if (third !== second){
                this.points.push(third);
            }
            if (this.last !== third){
                this.points.push(this.last);
            }
        }
    }

    build(){
        this.saveSegment();
        return this.points;
    }

}