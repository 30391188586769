import { useTranslation } from "react-i18next";
import {useThemeSwitcher} from "react-css-theme-switcher";

interface ColorSchemeDropdownProps {
    className?: string;
}

export const ColorSchemeDropdown = ({className}  : ColorSchemeDropdownProps) => {
    const { t } = useTranslation();
    const { switcher, themes, currentTheme } = useThemeSwitcher();
    const handleSchemeChange = (value: string) => {
        switcher({theme: value === themes.dark ? themes.dark : themes.light});
    }
    return (
        <select className={`${className} disable-select`} defaultValue={currentTheme} onChange={(e) => handleSchemeChange(e.target.value)}>
            <option value={themes.light}>{t("color_scheme_light")}</option>
            <option value={themes.dark}>{t("color_scheme_dark")}</option>
        </select>
    );
}

export default ColorSchemeDropdown;