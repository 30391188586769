import {useEffect, useState} from "react";
import {selectToken} from "../../../features/account/accountSlice";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import PagedData from "../../../models/PagedData";
import {Patient} from "../../../models/Patient";
import * as ApiHelper from '../../../api/ApiHelper';
import {SORT_BY_NAME} from "../../../models/SortType";
import React from "react";
import handleErrors from "../../../helpers/ErrorHandler";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {FetchError} from "../FetchError/FetchError";
import {PatientsLong} from "../PatientsLong/PatientsLong";
import {SearchInput} from "../SearchInput/SearchInput";
import PatientsPlaceholder from "../PatientsPlaceholder/PatientsPlaceholder";
import {ListPagination} from "../Pagination/ListPagination";
import {useTranslation} from "react-i18next";
import {AccessType} from "../../../models/AccessType";

interface PatientsListProps {
    showTitle?: boolean;
    pageSize: number;
    userId: string | null;
    accessType: AccessType;
    patientClickHandler: (id: string) => void;
    contextMenuHandler?: (id: string, anchorX: number, anchorY: number) => void;
}

export const PatientsList: React.FC<PatientsListProps> = ({
                                                              showTitle = false,
                                                              pageSize,
                                                              userId,
                                                              accessType,
                                                              patientClickHandler,
                                                              contextMenuHandler
                                                          }: PatientsListProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const controller = useAbortController();
    const token = useAppSelector(selectToken);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filter, setFilter] = useState("");
    const [patients, setPatients] = useState([] as Patient[]);
    const handleFilterChange = (filter: string) => {
        setFilter(filter);
        fetchPatients(currentPage, filter);
    };
    const paginationClickHandler = (page: number) => {
        setFetchingState(true);
        setCurrentPage(page);
    };
    const patientsHandler = (pagedData: PagedData<Patient>) => {
        setFetchingState(false);
        setErrorState(false);
        setPatients(pagedData.data);
        setCurrentPage(pagedData.page);
        setTotalPages(pagedData.totalPages);
        setTotalRecords(pagedData.totalRecords);
    };
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
        }
    };
    const fetchPatients = (page: number, filter: string) => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getPatients(userToken, userId, accessType.valueOf(), filter !== "" ? filter : null, SORT_BY_NAME, page, pageSize, controller, patientsHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchPatients(currentPage, filter), [currentPage, filter]);     // eslint-disable-line
    if (!isFetching && hasError) {
        return (
            <FetchError onRetry={() => fetchPatients(currentPage, filter)}/>
        );
    }
    const patientComponents = patients.map(patient => <PatientsLong key={patient.id} patient={patient}
                                                                    onClick={() => patientClickHandler(patient.id)}
                                                                    onContextMenuHandler={(x, y) => contextMenuHandler ? contextMenuHandler(patient.id, x, y) : null}/>)
    const hasData = patientComponents.length > 0;
    return (
        <div className="data-list-container">
            <div className="data-list">
                {showTitle &&
                <div className="data-list-title">{t("total_patients")}: <span>{totalRecords}</span></div>
                }
                <SearchInput text={filter} onChange={handleFilterChange}/>
                {isFetching && <AsyncIndicator/>}
                {!isFetching && (hasData ? (
                    <table>
                        <thead>
                        <tr className="table-header">
                            <th>{t("name")}</th>
                            <th>{t("birthdate")}</th>
                            <th>{t("phone")}</th>
                            <th>{t("email")}</th>
                            <th>{t("gender")}</th>
                            <th>{t("height")}</th>
                            <th>{t("weight")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {patientComponents}
                        </tbody>
                    </table>
                ) : (
                    <PatientsPlaceholder className="my-4"/>
                ))}
            </div>
            {!isFetching && totalPages > 1 &&
            <ListPagination page={currentPage} totalPages={totalPages} clickHandler={paginationClickHandler}/>}
        </div>
    );
}