import {Avatar} from "@material-ui/core";
import {Menu} from "@material-ui/icons";
import React, {useState} from 'react';
import {NavLink, useHistory} from "react-router-dom";
import {logout, selectUserInfo} from "../../../features/account/accountSlice";
import {useAccessInfo, useAppDispatch, useAppSelector, useMediaQuery} from "../../../hooks";
import * as AppRoutes from '../../../routes';
import HeaderLogo from "./HeaderLogo";
import {Role} from '../../../models/Role';
import './Header.css';
import LanguageDropdown from "../../Widgets/LanguageDropdown/LanguageDropdown";
import {useTranslation} from "react-i18next";
import {getUserPhotoUrl} from "../../../api/ApiHelper";
import {AccountDropdown} from "./AccountDropdown";

function Header() {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const isSupport = useAccessInfo(Role.Support);
    const media = useMediaQuery();
    const links = [
        { text: t("home"), path: AppRoutes.DASHBOARD_ROUTE },
        { text: t("my_patients"), path: AppRoutes.MY_PATIENTS_ROUTE },
        { text: t("my_records"), path: AppRoutes.MY_RECORDS_ROUTE },
        { text: t("shared_patients"), path: AppRoutes.SHARED_PATIENTS_ROUTE },
        { text: t("shared_records"), path: AppRoutes.SHARED_RECORDS_ROUTE }
    ];
    if (isSupport) {
        links.push({ text: t("patients"), path: AppRoutes.PATIENTS_ROUTE });
        links.push({ text: t("records"), path: AppRoutes.RECORDS_ROUTE });
        links.push({ text: t("users"), path: AppRoutes.USERS_ROUTE });
    }
    const handleProfileClick = () => {
        history.push(AppRoutes.PROFILE_ROUTE);
    };
    const handleSettingsClick = () => {
        history.push(AppRoutes.SETTINGS_ROUTE);
    };
    const handleLogOutClick = () => {
        dispatch(logout());
    };
    const [isExpanded, setExpanded] = useState(false);
    const handleToggleClick = () => {
        setExpanded(!isExpanded);
    }
    const handleLinkClick = () => {
        if (isExpanded) {
            setExpanded(false);
        }
    };
    const navLinkClassName = media.lg ? "header-link" : "drawer-link";
    const navLinks = links.map(link => <NavLink key={link.path} className={`${navLinkClassName} disable-select`} to={link.path} onClick={handleLinkClick}>{link.text}</NavLink>)
    const photoUrl = getUserPhotoUrl(userInfo?.photoUrl);
    if (media.lg) {
        return (
            <div className="header">
                <NavLink to={AppRoutes.HOME_ROUTE}><HeaderLogo /></NavLink>
                <div className="header-links">
                    {navLinks}
                </div>
                <div className="header-options">
                    <div className="header-profile">
                        <Avatar className="header-avatar-img" alt={userInfo?.name} src={photoUrl} onClick={handleProfileClick} />
                        <AccountDropdown userName={userInfo?.name ?? ""} onProfileClick={handleProfileClick} onSettingsClick={handleSettingsClick} onLogoutClick={handleLogOutClick}/>
                    </div>
                    <div className="header-options-divider" />
                    <div className="header-options-dropdown">
                        <LanguageDropdown />
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="header">
                <NavLink className="drawer-logo" to={AppRoutes.HOME_ROUTE}><HeaderLogo /></NavLink>
                <div className="drawer-toggle" onClick={handleToggleClick}><Menu /></div>
                <div className={`drawer ${isExpanded ? '' : 'hidden'}`}>
                    <div className="drawer-user-info">
                        <div className="drawer-avatar">
                            <Avatar className="drawer-avatar-img" alt={userInfo?.name} src={photoUrl} onClick={handleProfileClick} />
                        </div>
                        <div className="drawer-profile-link" onClick={handleProfileClick}>{userInfo?.name ?? ""}</div>
                    </div>
                    <NavLink className="drawer-link disable-select" to={AppRoutes.PROFILE_ROUTE} onClick={handleLinkClick}>{t("profile")}</NavLink>
                    <NavLink className="drawer-link disable-select" to={AppRoutes.SETTINGS_ROUTE} onClick={handleLinkClick}>{t("settings")}</NavLink>
                    <NavLink className="drawer-link disable-select" to={"logout"} onClick={handleLogOutClick}>{t("sign_out")}</NavLink>
                    <div className="drawer-divider" />
                    {navLinks}
                    <div className="drawer-divider" />
                    <div className="drawer-options-dropdown">
                        <div className="title disable-select">{t("language")}:</div>
                        <LanguageDropdown />
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;