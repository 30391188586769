import React, { useEffect, useState } from "react";
import SvgLockIcon from "../../../assets/svg/LockIcon";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import { Button } from "../Button/Button";
import { InputBox } from "../InputBox/InputBox";
import * as ApiHelper from "../../../api/ApiHelper";
import * as ErrorCodes from "../../../api/ErrorCodes";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import { useAppDispatch } from "../../../hooks";
import { updateToken } from "../../../features/account/accountSlice";
import { UserToken } from "../../../models/UserToken";
import {useTranslation} from "react-i18next";

enum Field {
    CurrentPassword,
    NewPassword,
    NewPasswordConfirm
}

interface DialogChangePasswordProps {
    token: string;
    controller: AbortController;
    cancelButtonClickHandler: () => void;
}

export const DialogChangePassword: React.FC<DialogChangePasswordProps> = ({ token, controller, cancelButtonClickHandler }: DialogChangePasswordProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [badField, setBadField] = useState(null as Field | null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const handleCurrentPasswordChange = (value: string) => setCurrentPassword(value);
    const handleNewPasswordChange = (value: string) => setNewPassword(value);
    const handleNewPasswordConfirmChange = (value: string) => setNewPasswordConfirm(value);
    const [isFetching, setFetchingState] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null as string | null);
    const [dialogTop, setDialogTop] = useState(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    const scrollHandler = () => {
        setDialogTop(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    };
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    });
    const keyDownHandler = (e : KeyboardEvent) => {
        if (e.key === "Enter"){
            handleChangeButtonClick();
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        }
    });
    const userTokenHandler = (userToken: UserToken) => {
        setFetchingState(false);
        setErrorMessage(null);
        dispatch(updateToken(userToken));
        cancelButtonClickHandler();
    };
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.BadCredentials) {
                setErrorMessage(t("wrong_password"));
                setBadField(Field.CurrentPassword);
            } else if (error.ResultCode === ErrorCodes.BadPassword) {
                setErrorMessage(t("incorrect_password"));
                setBadField(Field.NewPassword);
            } else {
                setErrorMessage(t("connection_problem"));
                setBadField(null);
            }
        }
    };
    const handleChangeButtonClick = () => {
        if (currentPassword.trim() === '') {
            setBadField(Field.CurrentPassword);
            setErrorMessage(t("enter_password"));
            return;
        }
        if (newPassword.trim() === '') {
            setBadField(Field.NewPassword);
            setErrorMessage(t("enter_password"));
            return;
        }
        if (newPassword.trim() !== newPasswordConfirm.trim()) {
            setBadField(Field.NewPasswordConfirm);
            setErrorMessage(t("password_mismatch"));
            return;
        }
        setBadField(null);
        setErrorMessage(null);
        setFetchingState(true);
        ApiHelper.changePassword(token, currentPassword ,newPassword, controller, userTokenHandler, errorHandler);
    };
    const documentHeight = document.documentElement.offsetHeight;
    return (
        <div className="dialog-container" style={{ height: documentHeight }}>
            {isFetching ? (
                <div className="window-modal" style={{ marginTop: dialogTop }}>
                    <AsyncIndicator />
                </div>
            ) : (
                <div className="window-modal" style={{ marginTop: dialogTop }}>

                    <div className="window-modal-title disable-select pb-4 d-flex justify-content-center">{t("change_password_title")}</div>
                    <div className="window-modal-error d-flex justify-content-center align-items-center my-2">
                        {errorMessage ? errorMessage : ""}
                    </div>
                    <InputBox className="my-2" inputType="password" title={t("current_password")} initialValue={currentPassword} hasError={badField === Field.CurrentPassword} onChange={handleCurrentPasswordChange} icon={<SvgLockIcon />} />
                    <InputBox className="my-2" inputType="password" title={t("new_password")} initialValue={newPassword} hasError={badField === Field.NewPassword} onChange={handleNewPasswordChange} icon={<SvgLockIcon />} />
                    <InputBox className="my-2" inputType="password" title={t("confirm_password")} initialValue={newPasswordConfirm} hasError={badField === Field.NewPasswordConfirm} onChange={handleNewPasswordConfirmChange} icon={<SvgLockIcon />} />
                    <div className="d-flex justify-content-around">
                        <Button className="mt-4" text={t("change")} danger={true} onClick={handleChangeButtonClick} />
                        <Button className="mt-4" text={t("cancel")} onClick={cancelButtonClickHandler} />
                    </div>
                </div>)
            }
        </div >
    );
}