import React from 'react';
import { formatAccountState, formatBirthdate, formatConfirmationState, formatRole } from '../../../helpers/FormatHelper';
import { User } from '../../../models/User';
import {useTranslation} from "react-i18next";


interface UsersProps {
    user: User;
    onClick: () => void;
}

const UsersLong: React.FC<UsersProps> = ({ user, onClick }: UsersProps) => {
    const {t} = useTranslation();
    return (
        <tr onClick={onClick}>
            <td align="center">{user.email}</td>
            <td align="center">{formatRole(t, user.role)}</td>
            <td align="center">{user.name}</td>
            <td align="center">{formatBirthdate(user.registrationDate)}</td>
            <td align="center">{formatConfirmationState(t, user.emailConfirmed)}</td>
            <td align="center">{formatAccountState(t, user.accountActive)}</td>
        </tr>
        );
}

export default UsersLong;