import './CommentView.css';
import React, {Fragment} from "react";
import ReplyIcon from "../../../assets/svg/ReplyIcon";
import EnvelopeIcon from "../../../assets/svg/EnvelopeIcon";
import DeleteCommentIcon from "../../../assets/svg/DeleteCommentIcon";
import {useTranslation} from "react-i18next";
import {useAccessInfo} from "../../../hooks";
import {Role} from "../../../models/Role";
import {ReferenceCommentView} from "./ReferenceCommentView";
import AssignmentIcon from "../../../assets/svg/AssignmentIcon";

interface ReferenceCommentData {
    id: string;
    user: string;
    text: string;
    timestamp: string;
}

interface CommentViewProps {
    id: string;
    user: string;
    text: string;
    timestamp: string;
    referenceComment?: ReferenceCommentData;
    onUserClick: () => void;
    onReplyClick: () => void;
    onDeleteClick: () => void;
    onReferenceClick: () => void;
    onConclusionClick?: (text: string) => void;
}

export const CommentView: React.FC<CommentViewProps> = ({
                                                            id,
                                                            user,
                                                            text,
                                                            timestamp,
                                                            referenceComment,
                                                            onUserClick,
                                                            onReplyClick,
                                                            onDeleteClick,
                                                            onReferenceClick,
                                                            onConclusionClick
                                                        }: CommentViewProps) => {
    const {t} = useTranslation();
    const isSupport = useAccessInfo(Role.Support);
    return (
        <div id={id} className="d-flex flex-column align-items-stretch my-2">
            <div className="d-flex flex-row align-self-start mx-3">
                <span className="comments-secondary comments-secondary-clickable"
                      onClick={onUserClick}><EnvelopeIcon/> {user}</span>
            </div>
            <div className="my-1 py-2 px-3 d-flex flex-column align-items-stretch comments-border">
                {referenceComment &&
                <div className="mb-1 comments-reference-container" onClick={onReferenceClick}>
                    <ReferenceCommentView id={referenceComment.id} user={referenceComment.user}
                                          text={referenceComment.text}
                                          timestamp={referenceComment.timestamp} clip={true}/>
                </div>
                }
                <span className="comments-primary">{text}</span>
            </div>
            <div className="d-flex flex-row justify-content-between mx-3">
                <div className="d-flex flex-row">
                    <span className="comments-secondary comments-secondary-clickable"
                          onClick={onReplyClick}><ReplyIcon/> {t("reply")}</span>
                    {onConclusionClick &&
                    <Fragment>
                        <div className="mx-1"/>
                        <span className="comments-secondary comments-secondary-clickable"
                              onClick={() => onConclusionClick(text)}><AssignmentIcon/> {t("to_conclusion")}</span>
                    </Fragment>
                    }
                    {isSupport &&
                    <Fragment>
                        <div className="mx-1"/>
                        <span className="comments-secondary comments-secondary-clickable"
                              onClick={onDeleteClick}><DeleteCommentIcon/> {t("delete_comment")}</span>
                    </Fragment>
                    }
                </div>
                <span className="comments-secondary">{timestamp}</span>
            </div>
        </div>
    );
}