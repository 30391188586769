import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import './StethoscopeViewer.css';
import {WaveFile} from "wavefile";
import {formatDateTime, formatDurationMs, formatPatientName, formatTimestamp} from "../../../helpers/FormatHelper";
import {HorizontalScrollbar} from "../HorizontalScrollbar/HorizontalScrollbar";
import SvgZoomOutHorizontalIcon from "../../../assets/svg/ZoomOutHorizontalIcon";
import SvgZoomInHorizontalIcon from "../../../assets/svg/ZoomInHorizontalIcon";
import SvgPrintIcon from "../../../assets/svg/PrintIcon";
import SvgSaveIcon from "../../../assets/svg/SaveIcon";
import TestWavIcon from "../../../assets/svg/TestWavIcon";
import CompandIcon from "../../../assets/svg/CompandIcon";
import DownloadWavIcon from "../../../assets/svg/DownloadWavIcon";
import {StethoscopeMonitor} from "./StethoscopeMonitor";
import {WavFileFormat} from "../../../sts/WavFileFormat";
import {WavPlayer} from "./WavPlayer";
import {compandData, getStethoscopeChannelNames, normalizeChannel} from "../../../sts/WavUtils";
import {getBellFilter, getDiaphragmFilter} from "../../../sts/ButterworthFilter";
import {PlotPoint, PlotPointsBuilder} from "../../../helpers/PlotPointsBuilder";
import {downloadFile} from "../../../helpers/DownloadHelper";
import {Record} from "../../../models/Record";
import {StethoscopeRecord} from "../../../models/StethoscopeRecord";
import {isEmpty} from "../../../helpers/TextUtils";
import {useTranslation} from "react-i18next";
import {DialogStethoscopeTestRecord} from "../DialogStethoscopeTestRecord/DialogStethoscopeTestRecord";
import {downloadStethoscopePdf, printStethoscopePdf} from "../../../api/ApiHelper";
import {StethoscopeReportSettings} from "../../../models/StethoscopeReportSettings";
import {Toast} from "../Toast/Toast";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import LandscapeIcon from "../../../assets/svg/LandscapeIcon";
import PortraitIcon from "../../../assets/svg/PortraitIcon";
import SaveAllIconSvg from "../../../assets/svg/SaveAllIcon";
import SaveSelectedIconSvg from "../../../assets/svg/SaveSelectedIcon";
import CheckIconSvg from "../../../assets/svg/CheckIcon";
import CancelIconSvg from "../../../assets/svg/CancelIcon";
import SvgMoveLeftIcon from "../../../assets/svg/MoveLeftIcon";
import SvgMoveRightIcon from "../../../assets/svg/MoveRightIcon";
import {TIME_SCALES} from "../../../sts/StethoscopeScales";

export const PX_PER_MM = 3;
export const NUMBER_OF_CHANNELS = 3;
export const MM_PER_CHANNEL = 55;
export const CHANNEL_NAME_HEIGHT_MM = 5;

const VIEW = 0;
const SAVE = 1;
const PRINT = 2;

const PORTRAIT = 0;
const LANDSCAPE = 1;

const ALL = 0;
const SELECTED = 1;

interface Props {
    record: Record;
    stethoscopeRecord: StethoscopeRecord,
    wavData: WaveFile;
    compand: boolean;
    timeScaleIndex: number;
    compandChangeHandler: (state: boolean) => void;
    timeScaleIndexChangeHandler: (index: number) => void;
}

const StethoscopePlayerContext = React.createContext(new WavPlayer(new AudioContext()));

export const StethoscopeViewer: React.FC<Props> = ({
                                                       record,
                                                       stethoscopeRecord,
                                                       wavData,
                                                       compand,
                                                       timeScaleIndex,
                                                       compandChangeHandler,
                                                       timeScaleIndexChangeHandler
                                                   }: Props) => {
    const {t} = useTranslation();
    const controller = useAbortController();
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();
    const [buttonMode, setButtonMode] = useState(VIEW);
    const [printLayout, setPrintLayout] = useState(LANDSCAPE);
    const [printMode, setPrintMode] = useState(ALL);
    const [savePosition, setSavePosition] = useState(null as number | null);
    const [moveLeft, setMoveLeft] = useState(false);
    const [moveRight, setMoveRight] = useState(false);
    const [pdfGenerationInProgress, setPdfGenerationInProgress] = useState(false);
    const [hasPdfError, setPdfErrorState] = useState(false);
    const [showTestRecordDialog, setShowTestRecordDialog] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const numberOfSamples = useMemo(() => wavData.getSamples().length, [wavData]);
    const [width, setWidth] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const timeScale = useMemo(() => TIME_SCALES[timeScaleIndex], [timeScaleIndex]);
    const sampleRate = useMemo(() => (wavData.fmt as WavFileFormat).sampleRate, [wavData]);
    const samplesPerScreen = useMemo(() => width / PX_PER_MM / timeScale * sampleRate, [width, timeScale, sampleRate]);
    const lastSample = useMemo(() => Math.min(scrollPosition + samplesPerScreen, numberOfSamples - 1), [scrollPosition, samplesPerScreen, numberOfSamples]);
    const firstSample = useMemo(() => Math.max(lastSample - samplesPerScreen, 0), [lastSample, samplesPerScreen]);
    const [shouldScrollByPositionChange, setShouldScrollByPositionChange] = useState(false);
    const samplesPerPage = useMemo(() => {
        if (printMode === ALL) {
            return null;
        } else {
            const plotWidth = (printLayout === LANDSCAPE ? 240 : 190);
            return Math.ceil(plotWidth / timeScale * sampleRate);
        }

    }, [printMode, printLayout, sampleRate, timeScale]);
    const player = useContext(StethoscopePlayerContext);
    const [activeChannel, setActiveChannel] = useState(0);
    const [position, setPosition] = useState(0);
    const [isPlaying, setPlaying] = useState(false);
    useLayoutEffect(() => {
        setScrollPosition(0);
        setActiveChannel(0);
        setPosition(0);
        setPlaying(false);
    }, [wavData]);
    useLayoutEffect(() => {
        return () => player.stop();
    }, [player, wavData]);
    useLayoutEffect(() => player.setSampleRate(sampleRate), [player, sampleRate]);
    useEffect(() => {
        player.setPositionChangeListener(p => {
            const newPosition = Math.min(numberOfSamples, Math.max(0, p));
            setPosition(newPosition);
            if (shouldScrollByPositionChange) {
                if (newPosition > lastSample) {
                    setScrollPosition(Math.max(0, newPosition - samplesPerScreen));
                }
                if (newPosition < firstSample) {
                    setScrollPosition(newPosition);
                }
            }
        });
        return () => player.setPositionChangeListener(null);
    });
    useEffect(() => {
        player.setPlaybackEndListener(() => setPlaying(false));
        return () => player.setPlaybackEndListener(null);
    });
    const height = useMemo(() => NUMBER_OF_CHANNELS * MM_PER_CHANNEL * PX_PER_MM, []);
    const samples = useMemo(() => wavData.getSamples().map(s => s / 32767), [wavData]);
    const compandedData = useMemo(() => compand ? samples.map(s => compandData(s)) : samples, [samples, compand]);
    const audioData = useMemo(() => {
        let extended = [];
        let bell = [];
        let diaphragm = [];
        const bellFilter = getBellFilter();
        const diaphragmFilter = getDiaphragmFilter();
        for (let i = 0; i < compandedData.length; i++) {
            let sample = compandedData[i];
            extended.push(sample);
            bell.push(bellFilter.process(sample));
            diaphragm.push(diaphragmFilter.process(sample));
        }
        normalizeChannel(bell);
        normalizeChannel(diaphragm);
        return [extended, bell, diaphragm];
    }, [compandedData]);
    const drawData = useMemo(() => {
        const amplitudePx = (MM_PER_CHANNEL - CHANNEL_NAME_HEIGHT_MM) / 2 * PX_PER_MM;
        const extended = audioData[0].map(s => s * amplitudePx);
        const bell = audioData[1].map(s => s * amplitudePx);
        const diaphragm = audioData[2].map(s => s * amplitudePx);
        return [extended, bell, diaphragm];
    }, [audioData]);
    const timeScaleFactor = useMemo(() => timeScale * PX_PER_MM / sampleRate, [timeScale, sampleRate]);
    const timeScaledData = useMemo(() => {
        const plotPoints = new Array<Array<PlotPoint>>();
        for (let i = 0; i < NUMBER_OF_CHANNELS; i++) {
            const builder = new PlotPointsBuilder();
            for (let j = 0; j <= numberOfSamples; j++) {
                builder.addData(j * timeScaleFactor, drawData[i][j]);
            }
            plotPoints.push(builder.build());
        }
        return plotPoints;
    }, [drawData, timeScaleFactor, numberOfSamples]);
    const drawPoints = useMemo(() => {
        const startX = firstSample * timeScaleFactor;
        const endX = lastSample * timeScaleFactor;
        const points = new Array<Array<number>>();
        for (let i = 0; i < NUMBER_OF_CHANNELS; i++) {
            const channelPoints = new Array<number>();
            const offsetY = (i * MM_PER_CHANNEL + CHANNEL_NAME_HEIGHT_MM + (MM_PER_CHANNEL - CHANNEL_NAME_HEIGHT_MM) / 2) * PX_PER_MM;
            const channel = timeScaledData[i];
            let isFirst = true;
            for (let j = 0; j < channel.length; j++) {
                const point = channel[j];
                if (point.x >= startX && point.x <= endX) {
                    if (isFirst) {
                        if (j > 0) {
                            const previousPoint = channel[j - 1];
                            channelPoints.push(previousPoint.x - startX, -1 * previousPoint.y + offsetY)
                        }
                        isFirst = false;
                    }
                    channelPoints.push(point.x - startX, -1 * point.y + offsetY)
                }
                if (point.x > endX) {
                    channelPoints.push(point.x - startX, -1 * point.y + offsetY)
                    break;
                }
            }
            points.push(channelPoints);
        }
        return points;
    }, [firstSample, lastSample, timeScaledData, timeScaleFactor]);
    const cursorPosition = useMemo(() => (position - firstSample) * timeScaleFactor, [position, timeScaleFactor, firstSample])

    useLayoutEffect(() => {
        setWidth(container.current?.clientWidth ?? 0);
    }, [container.current?.clientWidth]);
    const resizeEventListener = () => setWidth(container.current?.clientWidth ?? 0);
    useEffect(() => {
        window.addEventListener("resize", resizeEventListener);
        return (() => {
            window.removeEventListener("resize", resizeEventListener)
        });
    });
    const testWavClickHandler = () => {
        setShowTestRecordDialog(true);
    };
    const downloadWavClickHandler = () => {
        const file = new WaveFile();
        file.fromScratch(1, sampleRate, '16', audioData[activeChannel].map(s => s * 32767));
        const channelNames = getStethoscopeChannelNames(t);
        const fileName = `${formatDateTime(record.dateTime)} - ${formatPatientName(t, record.patientId === null, record.patientName)} ${isEmpty(stethoscopeRecord.locationDescription) ? '' : `- ${stethoscopeRecord.locationDescription}`} (${channelNames[activeChannel]}).wav`;
        downloadFile(file.toDataURI(), fileName);
    };
    const isMaxHorizontalZoom = timeScaleIndex === TIME_SCALES.length - 1;
    const isMinHorizontalZoom = timeScaleIndex === 0;
    const scrollHandler = (position: number) => {
        setShouldScrollByPositionChange(false);
        setScrollPosition(position);
    };
    const positionChangeHandler = (x: number, y: number) => {
        const index = Math.floor(Math.min(NUMBER_OF_CHANNELS, Math.max(0, y / (MM_PER_CHANNEL * PX_PER_MM))));
        if (index !== activeChannel) {
            setActiveChannel(index);
        }
        const newPosition = Math.floor(x / timeScaleFactor) + firstSample;
        if (newPosition >= 0 && newPosition < numberOfSamples) {
            player.stop();
            setPosition(newPosition);
            if (isPlaying) {
                player.play(audioData[index], newPosition);
            }
        }
    };
    const playbackControlButtonClickHandler = (index: number) => {
        let newState: boolean;
        if (index === activeChannel) {
            newState = !isPlaying;
        } else {
            setActiveChannel(index);
            newState = true;
        }
        player.stop();
        if (newState) {
            player.play(audioData[activeChannel], position);
        }
        setPlaying(newState);
        setShouldScrollByPositionChange(newState);
    };
    const notifyPdfGenerationInProgress = () => {
        setPdfErrorState(false);
        setPdfGenerationInProgress(false);
        setPdfGenerationInProgress(true);
    }
    const pdfErrorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            setPdfErrorState(false);
            setPdfGenerationInProgress(false);
            setPdfErrorState(true);
        }
    };
    const applySaveSettings = () => {
        if (token) {
            const settings = {
                recordId: record.id,
                studyId: record.stethoscopeStudy.stethoscopeId,
                fileId: stethoscopeRecord.fileId,
                position: Math.ceil(savePosition ?? -1),
                compand: compand,
                timeScale: timeScale,
                isLandscape: printLayout === LANDSCAPE,
                language: t("ui_language")
            } as StethoscopeReportSettings;
            const name = formatTimestamp(Date.now());
            notifyPdfGenerationInProgress();
            if (buttonMode === SAVE) {
                downloadStethoscopePdf(token.token, settings, name, controller, () => {
                }, pdfErrorHandler);
            }
            if (buttonMode === PRINT) {
                printStethoscopePdf(token.token, settings, controller, () => {
                }, pdfErrorHandler);
            }
        }
        setButtonMode(VIEW);
    };
    const moveSelectionRegion = (direction: number) => {
        if (direction > 0) {
            if (printMode === SELECTED) {
                setMoveRight(true);
            }
        }
        if (direction < 0) {
            if (printMode === SELECTED) {
                setMoveLeft(true);
            }
        }
        if (direction === 0) {
            setMoveRight(false);
            setMoveLeft(false);
        }
    };
    const switchButtonMode = (mode: number) => {
        if (mode !== VIEW) {
            player.stop();
            setPlaying(false);
        }
        setButtonMode(mode);
    }
    return (
        <div className={"stethoscope-viewer"} ref={container}>
            {width > 0 &&
            <StethoscopeMonitor width={width} height={height} firstSample={firstSample} lastSample={lastSample}
                                numberOfSamples={numberOfSamples} timeScale={timeScale} sampleRate={sampleRate}
                                drawPoints={drawPoints} activeChannel={activeChannel}
                                isPlaying={isPlaying} cursorPosition={cursorPosition}
                                playbackControlButtonClickHandler={playbackControlButtonClickHandler}
                                positionChangeHandler={positionChangeHandler} showSaveRegion={buttonMode !== VIEW}
                                saveRegionWidth={samplesPerPage}
                                savePositionChangeListener={setSavePosition} moveRegionLeft={moveLeft}
                                moveRegionRight={moveRight}/>}
            {width > 0 &&
            <div>
                <div
                    className="d-flex justify-content-between my-2 ecg-viewer-duration">
                    <span>{formatDurationMs(firstSample / sampleRate * 1000)}</span>
                    <span>{formatDurationMs(lastSample / sampleRate * 1000)}</span>
                </div>
                {samplesPerScreen < numberOfSamples &&
                <HorizontalScrollbar width={width} position={firstSample} max={numberOfSamples}
                                     screen={samplesPerScreen}
                                     onScrollPositionChanged={scrollHandler}/>
                }
            </div>}
            {width > 0 && buttonMode !== VIEW &&
            <div
                className="d-flex justify-content-center mt-4 stethoscope-viewer-instruction">{t(buttonMode === SAVE ? "setup_save_options" : "setup_print_options")}</div>
            }
            {width > 0 &&
            <div className="d-flex justify-content-center my-4">
                {buttonMode === VIEW &&
                <div className="d-flex justify-content-center mr-2">
                    <div className={`stethoscope-viewer-button ${compand ? "active" : ""}`}
                         onClick={() => compandChangeHandler(!compand)}>
                        <CompandIcon/><span>{t("amplification_tooltip")}</span></div>
                    <div className={`stethoscope-viewer-button ${isMaxHorizontalZoom ? "active" : ""}`}
                         onClick={() => timeScaleIndexChangeHandler(Math.min(TIME_SCALES.length - 1, timeScaleIndex + 1))}>
                        <SvgZoomOutHorizontalIcon/><span>{t("time_zoom_out_tooltip")}</span></div>
                    <div className={`stethoscope-viewer-button ${isMinHorizontalZoom ? "active" : ""}`}
                         onClick={() => timeScaleIndexChangeHandler(Math.max(0, timeScaleIndex - 1))}>
                        <SvgZoomInHorizontalIcon/><span>{t("time_zoom_in_tooltip")}</span>
                    </div>
                </div>
                }
                {buttonMode === VIEW &&
                <div className="d-flex justify-content-center mx-2">
                    <div className={`stethoscope-viewer-button`} onClick={downloadWavClickHandler}>
                        <DownloadWavIcon/><span>{t("save_wav_tooltip")}</span></div>
                    <div className={`stethoscope-viewer-button`} onClick={testWavClickHandler}>
                        <TestWavIcon/><span>{t("test_record_tooltip")}</span></div>
                </div>
                }
                {buttonMode === VIEW &&
                <div className="d-flex justify-content-center ml-2">
                    <div className="stethoscope-viewer-button" onClick={() => switchButtonMode(PRINT)}>
                        <SvgPrintIcon/><span>{t("print_tooltip")}</span></div>
                        <div className="stethoscope-viewer-button" onClick={() => switchButtonMode(SAVE)}>
                        <SvgSaveIcon/><span>{t("download_tooltip")}</span></div>
                        </div>
                    }
                        {buttonMode !== VIEW &&
                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-center mx-2">
                                <div
                                    className={`stethoscope-viewer-button ${printLayout === LANDSCAPE ? "active" : ""}`}
                                    onClick={() => setPrintLayout(LANDSCAPE)}>
                                    <LandscapeIcon/><span>{t("landscape")}</span></div>
                                <div className={`stethoscope-viewer-button ${printLayout === PORTRAIT ? "active" : ""}`}
                                     onClick={() => setPrintLayout(PORTRAIT)}>
                                    <PortraitIcon/><span>{t("portrait")}</span></div>
                            </div>
                            <div className="d-flex justify-content-center mx-2">
                                <div className={`stethoscope-viewer-button ${printMode === ALL ? "active" : ""}`}
                                     onClick={() => setPrintMode(ALL)}>
                                    <SaveAllIconSvg/><span>{t("whole_record")}</span></div>
                                <div className={`stethoscope-viewer-button ${printMode === SELECTED ? "active" : ""}`}
                                     onClick={() => setPrintMode(SELECTED)}>
                                    <SaveSelectedIconSvg/><span>{t("selected_fragment")}</span></div>
                                <div
                                    className={`stethoscope-viewer-button ${printMode === SELECTED ? "" : "disabled"} ${moveLeft ? "active" : ""}`}
                                    onMouseDown={() => moveSelectionRegion(-1)}
                                    onMouseUp={() => moveSelectionRegion(0)}>
                                    <SvgMoveLeftIcon/><span>{t("move_region_left")}</span></div>
                                <div
                                    className={`stethoscope-viewer-button ${printMode === SELECTED ? "" : "disabled"} ${moveRight ? "active" : ""}`}
                                    onMouseDown={() => moveSelectionRegion(1)} onMouseUp={() => moveSelectionRegion(0)}>
                                    <SvgMoveRightIcon/><span>{t("move_region_right")}</span></div>
                            </div>
                            <div className="d-flex justify-content-center ml-2">
                                <div className={"stethoscope-viewer-button"} onClick={() => switchButtonMode(VIEW)}>
                                    <CancelIconSvg/><span>{t("cancel")}</span></div>
                                <div className={"stethoscope-viewer-button"} onClick={applySaveSettings}>
                                    <CheckIconSvg/><span>{t("apply")}</span></div>
                            </div>
                        </div>
                        }
                </div>
                }
                {pdfGenerationInProgress &&
                <Toast text={t("pdf_generation_in_progress")} isError={false}/>
                }
                {hasPdfError &&
                <Toast text={t("error_pdf_generation")} isError={true}/>
                }
                {showTestRecordDialog &&
                <DialogStethoscopeTestRecord okButtonClickHandler={() => setShowTestRecordDialog(false)}/>}
            </div>
                );
            }