import React from "react";
import { useHistory } from "react-router-dom";
import { USERS_ROUTE } from "../../../routes";
import { DATA_LIST_PAGE_SIZE } from "../../../settings";
import { UsersList } from "../../Widgets/UsersList/UsersList";
import { Breadcrumbs } from "../../Widgets/Breadcrumbs/Breadcrumbs";

function Users() {
    const history = useHistory();
    const userClickHandler = (id: string) => {
        history.push(`${USERS_ROUTE}/${id}`);
    };
    return (
        <div>
            <Breadcrumbs />
            <UsersList pageSize={DATA_LIST_PAGE_SIZE} userClickHandler={userClickHandler} />
        </div>
    );
}

export default Users;