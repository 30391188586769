import React from "react";
import { formatDateTime, formatPatientName } from "../../../helpers/FormatHelper";
import { Record } from '../../../models/Record';
import { StudyIcon, StudyType } from "../StudyIcon/StudyIcon";
import {useTranslation} from "react-i18next";

interface RecordProps {
    record: Record;
    onClick: () => void;
    onContextMenuHandler: (anchorX: number, anchorY: number) => void;
}

export const RecordsLong: React.FC<RecordProps> = ({ record, onClick, onContextMenuHandler }: RecordProps) => {
    const {t} = useTranslation();
    const contextMenuHandler = (event : any) => {
        event.preventDefault();
        onContextMenuHandler(event.clientX, event.clientY);
    };
    return (
        <tr onClick={onClick} onContextMenu={contextMenuHandler}>
            <td align="center">{formatPatientName(t, record.patientId === null, record.patientName)}</td>
            <td align="center">{formatDateTime(record.dateTime)}</td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.State} hasText={true} isVisible={record.state !== null}/>
                </div>
            </td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.Ecg} hasText={true} isVisible={record.ecgStudy !== null}/>
                </div>
            </td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.Spiro} hasText={true} isVisible={record.spiroStudy !== null}/>
                </div>
            </td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.Stethoscope} hasText={true} isVisible={record.stethoscopeStudy !== null}/>
                </div>
            </td>
            <td align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.LtEcg} hasText={true} isVisible={record.ltEcgStudy !== null}/>
                </div>
            </td>
        </tr>
    );
}