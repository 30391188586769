import { useEffect, useLayoutEffect, useState } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { selectUserInfo } from './features/account/accountSlice'
import { Role } from './models/Role'
import type { RootState, AppDispatch } from './store'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export interface MediaQuery {
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
}

export const useMediaQuery : () => MediaQuery = () => {
    const [screenSize, setScreenSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateScreenSize() {
            setScreenSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateScreenSize);
        updateScreenSize();
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);

    return {
        xs: screenSize[0] > 0,
        sm: screenSize[0] > 576,
        md: screenSize[0] > 768,
        lg: screenSize[0] > 992,
        xl : screenSize[0] > 1200
    } as MediaQuery;
}

export const useAccessInfo: (role: Role) => boolean = (role: Role) => {
    const userInfo = useAppSelector(selectUserInfo);
    switch (role) {
        case Role.Doctor:
            return userInfo?.role === Role.Doctor || userInfo?.role === Role.Support || userInfo?.role === Role.Admin;
        case Role.Support:
            return userInfo?.role === Role.Support || userInfo?.role === Role.Admin;
        case Role.Admin:
            return userInfo?.role === Role.Admin;
        default:
            return false;
    }
    
};

export const useAbortController: () => AbortController = () => {
    const [controller] = useState(new AbortController());
    useEffect(() => {
        return () => {
            controller.abort();
        }
    }, [controller]);
    return controller;
}