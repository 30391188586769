import {useState} from "react";

export const COLOR_SETTINGS_KEY = "color";
export const ECG_MV_SCALE_SETTINGS_KEY = "ecg_mv";
export const ECG_TIME_SCALE_SETTINGS_KEY = "ecg_time";
export const ECG_REPORT_GRID_SETTINGS_KEY = "ecg_color"
export const STETHOSCOPE_TIME_SCALE_SETTINGS_KEY = "sts_time";

export const ECG_MV_SCALE_INDEX_DEFAULT_VALUE = 1;
export const ECG_TIME_SCALE_INDEX_DEFAULT_VALUE = 1;
export const ECG_REPORT_GRID_INDEX_DEFAULT_VALUE = 0;
export const STETHOSCOPE_TIME_SCALE_INDEX_DEFAULT_VALUE = 1;

function getSavedValue<T>(key : string, defaultValue : T) : T {
    const savedValue = localStorage.getItem(key);
    return savedValue ? JSON.parse(savedValue) : defaultValue;
}

function saveValue<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function usePersistentState<T>(key: string, defaultValue: T): [T, (v: T) => void] {
    const [state, setState] = useState(getSavedValue(key, defaultValue));
    const setPersistentState = (value: T) => {
        saveValue(key, value);
        setState(value);
    };
    return [state, setPersistentState];
}