import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {selectUserInfo} from "../../../features/account/accountSlice";
import {useAppSelector} from "../../../hooks";
import {SHARED_PATIENTS_ROUTE} from "../../../routes";
import {DATA_LIST_PAGE_SIZE} from "../../../settings";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {PatientsList} from "../../Widgets/PatientsList/PatientsList";
import {AccessType} from "../../../models/AccessType";
import {ControlledMenu, MenuItem, useMenuState} from "@szhsin/react-menu";
import {useTranslation} from "react-i18next";

function SharedPatients() {
    const {t} = useTranslation();
    const history = useHistory();
    const userInfo = useAppSelector(selectUserInfo);
    const {toggleMenu, ...menuProps} = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
    const [sharePatientId, setSharePatientId] = useState(null as string | null);
    const patientClickHandler = (id: string) => {
        history.push(`${SHARED_PATIENTS_ROUTE}/${id}`);
    };
    const contextMenuHandler = (id: string, x: number, y: number) => {
        setAnchorPoint({x: x, y: y});
        setSharePatientId(id);
        toggleMenu(true);
    };
    const shareClickHandler = () => {
        history.push(`${SHARED_PATIENTS_ROUTE}/${sharePatientId}/share`);
    };
    return (
        <div>
            <Breadcrumbs/>
            <PatientsList pageSize={DATA_LIST_PAGE_SIZE} userId={userInfo?.id ?? null} accessType={AccessType.Shared}
                          patientClickHandler={patientClickHandler} contextMenuHandler={contextMenuHandler}/>
            <ControlledMenu {...menuProps} anchorPoint={anchorPoint}
                            onClose={() => toggleMenu(false)} menuClassName={"context-menu"}>
                <MenuItem onClick={shareClickHandler}>{t("access_control")}</MenuItem>
            </ControlledMenu>
        </div>
    );
}

export default SharedPatients;