import React from "react";
import ScgEcgIcon from "../../../assets/svg/EcgIcon";
import SvgSpiroIcon from "../../../assets/svg/SpiroIcon";
import SvgStateIcon from "../../../assets/svg/StateIcon";
import SvgStethoscopeIcon from "../../../assets/svg/StethoscopeIcon";
import './StudyIcon.css';
import {useTranslation} from "react-i18next";

export enum StudyType {
    State,
    Ecg,
    Spiro,
    Stethoscope,
    LtEcg
}

interface StudyIconProps {
    type: StudyType;
    hasText?: boolean;
    isVisible: boolean;
}

export const StudyIcon: React.FC<StudyIconProps> = ({ type, hasText = false, isVisible }: StudyIconProps) => {
    const {t} = useTranslation();
    let icon;
    let text;
    switch (type) {
        case StudyType.State:
            icon = <SvgStateIcon />;
            text = t("state");
            break;
        case StudyType.Ecg:
            icon = <ScgEcgIcon />;
            text = t("ecg");
            break;
        case StudyType.Spiro:
            icon = <SvgSpiroIcon />;
            text = t("spiro");
            break;
        case StudyType.Stethoscope:
            icon = <SvgStethoscopeIcon />;
            text = t("stethoscope");
            break;
        case StudyType.LtEcg:
            icon = <ScgEcgIcon />;
            text = t("lt_ecg");
            break;
        default:
            icon = null;
            text = "";
            break;
    }
    return (
        <div className={`study-icon-container ${isVisible ? "" : "study-icon-hidden"}`}>
            {icon && icon}
            {(hasText && text) && <span>{text}</span>}
        </div>
        );
}