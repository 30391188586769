import {UserToken} from "../models/UserToken";
import {UserInfo} from "../models/UserInfo";
import * as ErrorCodes from './ErrorCodes';
import ErrorResponse, {makeErrorResponse} from "../models/ErrorResponse";
import {Patient} from "../models/Patient";
import {Record} from "../models/Record";
import {Comment} from "../models/Comment";
import PagedData from "../models/PagedData";
import {User} from "../models/User";
import {UserDetails} from "../models/UserDetails";
import {UserUpdate} from "../models/UserUpdate";
import {EcgData} from "../models/EcgData";
import {SpiroData} from "../models/SpiroData";
import {StethoscopeData} from "../models/StethoscopeData";
import {blobToDataURL, downloadFile} from "../helpers/DownloadHelper";
import {EcgReportSettings} from "../models/EcgReportSettings";
import {printPdf} from "../print/PrintHelper";
import {StethoscopeReportSettings} from "../models/StethoscopeReportSettings";
import {UserAccessData} from "../models/UserAccessData";
import {NotificationSettingsData} from "../models/NotificationSettingsData";
import {PatientAccessData} from "../models/PatientAccessData";
import {RecordAccessData} from "../models/RecordAccessData";

const baseUrl = process.env.NODE_ENV === 'development' ? "https://localhost:44351" : "https://my.binora.com.ua";

function buildQueryString(data: Map<string, string>): string {
    let params: string[] = [];
    data.forEach((value, key) => {
        if (value) {
            params.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
        }
    });
    if (params.length > 0) {
        return `?${params.join("&")}`;
    } else {
        return '';
    }
}

function fetchAnonymousGet(url: string, controller: AbortController) {
    return fetch(url, {
        signal: controller.signal,
        method: 'GET'
    });
}

function fetchAnonymousPost(url: string, body: any, controller: AbortController) {
    return fetch(url, {
        signal: controller.signal,
        method: 'POST',
        body: JSON.stringify(body),
        headers: {"Content-type": "application/json; charset=UTF-8"}
    });
}

function fetchAuthorizedGet(url: string, token: string, controller: AbortController) {
    return fetch(url, {
        signal: controller.signal,
        method: 'GET',
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
}

function fetchAuthorizedPost(url: string, token: string, body: any, controller: AbortController) {
    return fetch(url, {
        signal: controller.signal,
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": `Bearer ${token}`
        }
    });
}

function fetchAuthorizedUpload(url: string, token: string, body: any, controller: AbortController) {
    return fetch(url, {
        signal: controller.signal,
        method: 'POST',
        body: body,
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
}

function fetchAuthorizedDelete(url: string, token: string, id: string, controller: AbortController) {
    return fetch(`${url}/${id}`, {
        signal: controller.signal,
        method: 'DELETE',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": `Bearer ${token}`
        }
    });
}

export const authenticate = (email: string, password: string, controller: AbortController, success: (token: UserToken) => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        email: email,
        password: password
    };
    fetchAnonymousPost(`${baseUrl}/api/account/login`, body, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as UserToken), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const authenticateWithGoogle = (idToken: string, controller: AbortController, success: (token: UserToken) => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        idToken: idToken
    };
    fetchAnonymousPost(`${baseUrl}/api/account/loginWithGoogle`, body, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as UserToken), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const register = (email: string, userName: string, password: string, language: string, controller: AbortController, success: (token: UserToken) => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        email: email,
        userName: userName,
        password: password,
        language: language
    };
    fetchAnonymousPost(`${baseUrl}/api/account/register`, body, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as UserToken), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const registerWithGoogle = (idToken: string, language: string, controller: AbortController, success: (token: UserToken) => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        idToken: idToken,
        language: language
    };
    fetchAnonymousPost(`${baseUrl}/api/account/registerWithGoogle`, body, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as UserToken), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getProfile = (token: string, controller: AbortController, success: (userInfo: UserInfo) => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedGet(`${baseUrl}/api/account/me`, token, controller)
        .then(response => {
            if (response.ok) {
                response.json().then(json => success(json as UserInfo), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const updateProfile = (token: string, name: string, clinic: string, clinicDetails: string, info: string, controller: AbortController, success: (userInfo: UserInfo) => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        name: name,
        clinic: clinic,
        clinicDetails: clinicDetails,
        info: info
    };
    fetchAuthorizedPost(`${baseUrl}/api/account/me`, token, body, controller)
        .then(response => {
            if (response.ok) {
                response.json().then(json => success(json as UserInfo), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getNotificationSettings = (token: string, controller: AbortController, success: (settings: NotificationSettingsData) => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedGet(`${baseUrl}/api/account/notifications`, token, controller)
        .then(response => {
            if (response.ok) {
                response.json().then(json => success(json as NotificationSettingsData), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const setNotificationSettings = (token: string, language: string, dataShare: boolean, dataReject: boolean, dataRefuse: boolean, controller: AbortController, success: (settings: NotificationSettingsData) => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        language: language,
        dataShare: dataShare,
        dataReject: dataReject,
        dataRefuse: dataRefuse
    } as NotificationSettingsData;
    fetchAuthorizedPost(`${baseUrl}/api/account/notifications`, token, body, controller)
        .then(response => {
            if (response.ok) {
                response.json().then(json => success(json as NotificationSettingsData), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const changePassword = (token: string, oldPassword: string, newPassword: string, controller: AbortController, success: (token: UserToken) => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        oldPassword: oldPassword,
        newPassword: newPassword
    };
    fetchAuthorizedPost(`${baseUrl}/api/account/change-password`, token, body, controller)
        .then(response => {
            if (response.ok) {
                response.json().then(json => success(json as UserToken), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const restorePassword = (email: string, language: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        email: email,
        language: language
    };
    fetchAnonymousPost(`${baseUrl}/api/account/forgot-password`, body, controller).then(response => {
        if (response.ok) {
            success();
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const resendEmail = (token: string, language: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        language: language
    };
    fetchAuthorizedPost(`${baseUrl}/api/account/resend-email`, token, body, controller).then(response => {
        if (response.ok) {
            success();
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getPatient = (token: string, patientId: string, controller: AbortController, success: (patient: Patient) => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedGet(`${baseUrl}/api/v1/patient/${patientId}`, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as Patient), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getPatients = (token: string, userId: string | null, accessType: number | null, patientName: string | null, order: string | null, page: number | null, pageSize: number | null, controller: AbortController, success: (patients: PagedData<Patient>) => void, fail: (error: ErrorResponse) => void) => {
    let data = new Map<string, string>();
    if (userId) {
        data.set("userId", userId);
    }
    if (accessType){
        data.set("accessType", accessType.toString());
    }
    if (patientName) {
        data.set("patientName", patientName);
    }
    if (order) {
        data.set("order", order);
    }
    if (page) {
        data.set("page", `${page}`);
    }
    if (pageSize) {
        data.set("pageSize", `${pageSize}`);
    }
    let url = `${baseUrl}/api/v1/patient${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<Patient>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const savePatient = (token: string, patient: Patient, controller: AbortController, success: (patient: Patient) => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedPost(`${baseUrl}/api/v1/patient`, token, patient, controller)
        .then(response => {
            if (response.ok) {
                response.json().then(json => success(json as Patient), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const saveEcgConclusion = (token: string, recordId: string, conclusion: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    const body = {
        recordId: recordId,
        conclusion: conclusion
    };
    fetchAuthorizedPost(`${baseUrl}/api/v1/record/ecg-conclusion`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const saveStethoscopeConclusion = (token: string, recordId: string, conclusion: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    const body = {
        recordId: recordId,
        conclusion: conclusion
    };
    fetchAuthorizedPost(`${baseUrl}/api/v1/record/stethoscope-conclusion`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const saveSpiroConclusion = (token: string, recordId: string, conclusion: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    const body = {
        recordId: recordId,
        conclusion: conclusion
    };
    fetchAuthorizedPost(`${baseUrl}/api/v1/record/spiro-conclusion`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const saveComment = (token: string, recordId: string, studyId: string, text: string, replyId: string | null, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        recordId: recordId,
        studyId: studyId,
        text: text,
        replyId: replyId
    };
    fetchAuthorizedPost(`${baseUrl}/api/v1/comment`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const deletePatient = (token: string, id: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedDelete(`${baseUrl}/api/v1/patient`, token, id, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getRecord = (token: string, recordId: string, controller: AbortController, success: (record: Record) => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedGet(`${baseUrl}/api/v1/record/${recordId}`, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as Record), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getRecordWithToken = (token: string, controller: AbortController, success: (record: Record) => void, fail: (error: ErrorResponse) => void) => {
    fetchAnonymousGet(`${baseUrl}/api/v1/record/share/${token}`, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as Record), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getRecords = (token: string, userId: string | null, accessType: number | null, patientId: string | null, patientName: string | null, order: string | null, page: number | null, pageSize: number | null, controller: AbortController, success: (records: PagedData<Record>) => void, fail: (error: ErrorResponse) => void) => {
    let data = new Map<string, string>();
    if (userId) {
        data.set("userId", userId);
    }
    if (accessType){
        data.set("accessType", accessType.toString());
    }
    if (patientId) {
        data.set("patientId", patientId);
    }
    if (patientName) {
        data.set("patientName", patientName);
    }
    if (order) {
        data.set("order", order);
    }
    if (page) {
        data.set("page", `${page}`);
    }
    if (pageSize) {
        data.set("pageSize", `${pageSize}`);
    }
    let url = `${baseUrl}/api/v1/record${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<Record>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getComments = (token: string, recordId: string, studyId: string, controller: AbortController, success: (records: PagedData<Comment>) => void, fail: (error: ErrorResponse) => void) => {
    let data = new Map<string, string>();
    data.set("recordId", recordId);
    data.set("studyId", studyId);
    let url = `${baseUrl}/api/v1/comment${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<Comment>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const deleteRecord = (token: string, id: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedDelete(`${baseUrl}/api/v1/record`, token, id, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const deleteComment = (token: string, id: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedDelete(`${baseUrl}/api/v1/comment`, token, id, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getZipStudy = (token: string, recordId: string, studyId: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/record/study-zip/${recordId}/${studyId}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.text().then(json => {
                downloadZipStudy(json as string, `${studyId}.zip`);
                success();
            }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const downloadZipStudy = (token: string, fileName: string) => {
    let url = `${baseUrl}/api/v1/record/study-zip/${token}`;
    downloadFile(url, fileName);
}

export const getEcgData = (token: string, recordId: string, studyId: string, controller: AbortController, success: (study: EcgData) => void, fail: (error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/record/study/${recordId}/${studyId}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as EcgData), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getEcgDataWithToken = (token: string, studyId: string, controller: AbortController, success: (study: EcgData) => void, fail: (error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/record/share/${token}/${studyId}`;
    fetchAnonymousGet(url, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as EcgData), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getSpiroData = (token: string, recordId: string, studyId: string, controller: AbortController, success: (study: SpiroData) => void, fail: (error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/record/study/${recordId}/${studyId}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as SpiroData), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getStethoscopeData = (token: string, recordId: string, studyId: string, controller: AbortController, success: (study: StethoscopeData) => void, fail: (error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/record/study/${recordId}/${studyId}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as StethoscopeData), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getStethoscopeDataWithToken = (token: string, studyId: string, controller: AbortController, success: (study: StethoscopeData) => void, fail: (error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/record/share/${token}/${studyId}`;
    fetchAnonymousGet(url, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as StethoscopeData), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getRawData = (token: string, recordId: string, fileId: string, controller: AbortController, success: (data: Blob) => void, fail: (error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/record/study/${recordId}/${fileId}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.blob().then(blob => success(blob), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getRawDataWithToken = (token: string, fileId: string, controller: AbortController, success: (data: Blob) => void, fail: (error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/record/share/${token}/${fileId}`;
    fetchAnonymousGet(url, controller).then(response => {
        if (response.ok) {
            response.blob().then(blob => success(blob), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const makeEcgPdf = (token : string, settings : EcgReportSettings, controller: AbortController, success: (url: string) => void, fail :(error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/report/ecg`;
    fetchAuthorizedPost(url, token, settings, controller).then(response => {
        if (response.ok) {
            response.blob().then(blob => blobToDataURL(blob)).then(url => success(url));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const downloadEcgPdf = (token : string, settings : EcgReportSettings, name: string, controller: AbortController, success: () => void, fail :(error: ErrorResponse) => void) => {
    makeEcgPdf(token, settings, controller, url => {
        downloadFile(url, `${name}.pdf`);
        success();
    }, fail);
}

export const printEcgPdf = (token : string, settings : EcgReportSettings, controller: AbortController, success: () => void, fail :(error: ErrorResponse) => void) => {
    makeEcgPdf(token, settings, controller, url => {
        printPdf(url);
        success();
    }, fail);
}

export const makeStethoscopePdf = (token : string, settings : StethoscopeReportSettings, controller: AbortController, success: (url: string) => void, fail :(error: ErrorResponse) => void) => {
    let url = `${baseUrl}/api/v1/report/stethoscope`;
    fetchAuthorizedPost(url, token, settings, controller).then(response => {
        if (response.ok) {
            response.blob().then(blob => blobToDataURL(blob)).then(url => success(url));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const downloadStethoscopePdf = (token : string, settings : StethoscopeReportSettings, name: string, controller: AbortController, success: () => void, fail :(error: ErrorResponse) => void) => {
    makeStethoscopePdf(token, settings, controller, url => {
        downloadFile(url, `${name}.pdf`);
        success();
    }, fail);
}

export const printStethoscopePdf = (token : string, settings : StethoscopeReportSettings, controller: AbortController, success: () => void, fail :(error: ErrorResponse) => void) => {
    makeStethoscopePdf(token, settings, controller, url => {
        printPdf(url);
        success();
    }, fail);
}

export const changePhoto = (token: string, image: Blob, controller: AbortController, success: (photoUrl: string) => void, fail: (error: ErrorResponse) => void) => {
    const formData = new FormData();
    formData.append("image", new File([image], "image"));
    fetchAuthorizedUpload(`${baseUrl}/api/account/photo`, token, formData, controller).then(response => {
        if (response.ok) {
            response.json().then(json => {
                success(json.photoUrl as string)
            }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getUser = (token: string, userId: string, controller: AbortController, success: (user: UserDetails) => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedGet(`${baseUrl}/api/v1/user/${userId}`, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as UserDetails), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getUsers = (token: string, userName: string | null, order: string | null, page: number | null, pageSize: number | null, controller: AbortController, success: (patients: PagedData<User>) => void, fail: (error: ErrorResponse) => void) => {
    let data = new Map<string, string>();
    if (userName) {
        data.set("userName", userName);
    }
    if (order) {
        data.set("order", order);
    }
    if (page) {
        data.set("page", `${page}`);
    }
    if (pageSize) {
        data.set("pageSize", `${pageSize}`);
    }
    let url = `${baseUrl}/api/v1/user${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<User>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const saveUser = (token: string, user: UserUpdate, controller: AbortController, success: (patient: UserDetails) => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedPost(`${baseUrl}/api/v1/user`, token, user, controller)
        .then(response => {
            if (response.ok) {
                response.json().then(json => success(json as UserDetails), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const deleteUser = (token: string, id: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    fetchAuthorizedDelete(`${baseUrl}/api/v1/user`, token, id, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const resetPassword = (token: string, userId: string, newPassword: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        id: userId,
        password: newPassword
    };
    fetchAuthorizedPost(`${baseUrl}/api/v1/user/change-password`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
};

export const getUserPhotoUrl = (url: string | undefined) : string | undefined => {
    if (url) {
        if (url.startsWith("https://")){
            return url;
        } else {
            return `${baseUrl}/${url}`;
        }
    } else {
        return undefined;
    }
}

export const sharePatients = (token: string, email: string, dataIds: string[], controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        userEmail : email,
        shareRelatedData: null,
        dataIds: dataIds
    };
    fetchAuthorizedPost(`${baseUrl}/api/v1/share/patients`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const shareRecords = (token: string, email: string, shareRelatedData: boolean, dataIds: string[], controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void) => {
    let body = {
        userEmail : email,
        shareRelatedData: shareRelatedData,
        dataIds: dataIds
    };
    fetchAuthorizedPost(`${baseUrl}/api/v1/share/records`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const rejectPatientShare = (token: string, id: string[], controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void ) => {
    fetchAuthorizedPost(`${baseUrl}/api/v1/share/reject-patients`, token, id, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const rejectRecordShare = (token: string, id: string[], controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void ) => {
    fetchAuthorizedPost(`${baseUrl}/api/v1/share/reject-records`, token, id, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const refusePatientShare = (token: string, id: string[], controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void ) => {
    fetchAuthorizedPost(`${baseUrl}/api/v1/share/refuse-patients`, token, id, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const refuseRecordShare = (token: string, id: string[], controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void ) => {
    fetchAuthorizedPost(`${baseUrl}/api/v1/share/refuse-records`, token, id, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getPatientOwner = (token: string, id: string, controller: AbortController, success: (owner: UserAccessData) => void, fail: (error: ErrorResponse) => void ) => {
    let url = `${baseUrl}/api/v1/share/owner-patient/${id}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as UserAccessData), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getRecordOwner = (token: string, id: string, controller: AbortController, success: (patients: UserAccessData) => void, fail: (error: ErrorResponse) => void ) => {
    let url = `${baseUrl}/api/v1/share/owner-record/${id}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as UserAccessData), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getPatientsSharedByUser = (token: string, id: string, page: number | null, pageSize: number | null, controller: AbortController, success: (users: PagedData<PatientAccessData>) => void, fail: (error: ErrorResponse) => void ) => {
    let data = new Map<string, string>();
    if (page) {
        data.set("page", `${page}`);
    }
    if (pageSize) {
        data.set("pageSize", `${pageSize}`);
    }
    let url = `${baseUrl}/api/v1/share/patients-by/${id}${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<PatientAccessData>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getRecordsSharedByUser = (token: string, id: string, page: number | null, pageSize: number | null, controller: AbortController, success: (users: PagedData<RecordAccessData>) => void, fail: (error: ErrorResponse) => void ) => {
    let data = new Map<string, string>();
    if (page) {
        data.set("page", `${page}`);
    }
    if (pageSize) {
        data.set("pageSize", `${pageSize}`);
    }
    let url = `${baseUrl}/api/v1/share/records-by/${id}${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<RecordAccessData>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getUsersBySharedPatient = (token: string, id: string, page: number | null, pageSize: number | null, controller: AbortController, success: (users: PagedData<UserAccessData>) => void, fail: (error: ErrorResponse) => void ) => {
    let data = new Map<string, string>();
    if (page) {
        data.set("page", `${page}`);
    }
    if (pageSize) {
        data.set("pageSize", `${pageSize}`);
    }
    let url = `${baseUrl}/api/v1/share/users-patient/${id}${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<UserAccessData>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getUsersBySharedRecord = (token: string, id: string, page: number | null, pageSize: number | null, controller: AbortController, success: (users: PagedData<UserAccessData>) => void, fail: (error: ErrorResponse) => void ) => {
    let data = new Map<string, string>();
    if (page) {
        data.set("page", `${page}`);
    }
    if (pageSize) {
        data.set("pageSize", `${pageSize}`);
    }
    let url = `${baseUrl}/api/v1/share/users-record/${id}${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<UserAccessData>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getAssociatedUsersByUserId = (token: string, id: string | null, page: number | null, pageSize: number | null, controller: AbortController, success: (users: PagedData<UserAccessData>) => void, fail: (error: ErrorResponse) => void ) => {
    let data = new Map<string, string>();
    if (page) {
        data.set("page", `${page}`);
    }
    if (pageSize) {
        data.set("pageSize", `${pageSize}`);
    }
    let url = `${baseUrl}/api/v1/share/users-by${id ? `/${id}` : "/_"}${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<UserAccessData>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const getUsersAssociatedWithByUserId = (token: string, id: string | null, page: number | null, pageSize: number | null, controller: AbortController, success: (users: PagedData<UserAccessData>) => void, fail: (error: ErrorResponse) => void ) => {
    let data = new Map<string, string>();
    if (page) {
        data.set("page", `${page}`);
    }
    if (pageSize) {
        data.set("pageSize", `${pageSize}`);
    }
    let url = `${baseUrl}/api/v1/share/users-with${id ? `/${id}` : "/_"}${buildQueryString(data)}`;
    fetchAuthorizedGet(url, token, controller).then(response => {
        if (response.ok) {
            response.json().then(json => success(json as PagedData<UserAccessData>), reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
        } else {
            response.json().then(json => fail(json as ErrorResponse));
        }
    }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const addAssociatedUser = (token: string, email: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void ) => {
    const body = {
        userEmail: email
    }
    fetchAuthorizedPost(`${baseUrl}/api/v1/share/add-user`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const removeAssociatedUser = (token: string, email: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void ) => {
    const body = {
        userEmail: email
    }
    fetchAuthorizedPost(`${baseUrl}/api/v1/share/remove-user`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}

export const refuseAssociatedUser = (token: string, email: string, controller: AbortController, success: () => void, fail: (error: ErrorResponse) => void ) => {
    const body = {
        userEmail: email
    }
    fetchAuthorizedPost(`${baseUrl}/api/v1/share/refuse-user`, token, body, controller)
        .then(response => {
            if (response.ok) {
                success();
            } else {
                response.json().then(json => fail(json as ErrorResponse));
            }
        }, reason => fail(makeErrorResponse(reason, ErrorCodes.Unknown)));
}