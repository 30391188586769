import Footer from "../../Widgets/Footer/Footer";
import React, {useEffect, useState} from "react";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {RecordDetailsTable} from "../../Widgets/RecordDetailsTable/RecordDetailsTable";
import {useHistory, useParams} from "react-router-dom";
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import {Record} from "../../../models/Record";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from "../../../api/ApiHelper";
import {downloadFile} from "../../../helpers/DownloadHelper";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {Role} from "../../../models/Role";
import {selectToken} from "../../../features/account/accountSlice";
import {Toast} from "../../Widgets/Toast/Toast";
import {useTranslation} from "react-i18next";

interface PathParams {
    recordToken: string;
}

export function SharedRecordDetails(){
    const {t} = useTranslation();
    let {recordToken} = useParams<PathParams>();
    const controller = useAbortController();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const isSupport = useAccessInfo(Role.Support);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [hasDownloadError, setDownloadErrorState] = useState(false);
    const recordHandler = (record: Record) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
    };
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
        }
    };
    const downloadErrorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            setDownloadErrorState(false);
            setDownloadErrorState(true);
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecordWithToken(recordToken, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecord(), [token]);     // eslint-disable-line
    const handleViewEcgClick = () => {
        if (record && record.ecgStudy && record.ecgStudy.ecgId) {
            history.push(`${history.location.pathname}/ecg`);
        }
    };
    const handleViewSpiroClick = () => {
        if (record && record.spiroStudy && record.spiroStudy.spiroId) {
            history.push(`${history.location.pathname}/spiro`);
        }
    };
    const handleViewStethoscopeClick = () => {
        if (record && record.stethoscopeStudy && record.stethoscopeStudy.stethoscopeId) {
            history.push(`${history.location.pathname}/stethoscope`);
        }
    };
    const downloadStudy = (studyId: string | undefined) => {
        let userToken = token?.token;
        if (userToken && record && studyId) {
            ApiHelper.getZipStudy(userToken, record.id, studyId, controller, () => {
            }, downloadErrorHandler);
        }
    }
    const handleDownloadEcgClick = () => {
        downloadStudy(record?.ecgStudy?.ecgId);
    };
    const handleDownloadSpiroClick = () => {
        downloadStudy(record?.spiroStudy?.spiroId);
    };
    const handleDownloadStethoscopeClick = () => {
        downloadStudy(record?.stethoscopeStudy?.stethoscopeId);
    };
    const handleDownloadLtEcgClick = () => {
        const link = record?.ltEcgStudy?.recordLink;
        if (link) {
            downloadFile(link, null);
        }
    };
    const isOk = !hasError && !notFound && !isFetching;
    return (
        <div>
            <Breadcrumbs
                data={new Map([[record?.id ?? "_", record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record) &&
            <RecordDetailsTable record={record} isSupport={isSupport}
                                handleViewEcgClick={handleViewEcgClick}
                                handleDownloadEcgClick={handleDownloadEcgClick}
                                handleViewSpiroClick={handleViewSpiroClick}
                                handleDownloadSpiroClick={handleDownloadSpiroClick}
                                handleViewStethoscopeClick={handleViewStethoscopeClick}
                                handleDownloadStethoscopeClick={handleDownloadStethoscopeClick}
                                handleDownloadLtEcgClick={handleDownloadLtEcgClick}/>
            }
            {hasDownloadError &&
            <Toast text={t("error_downloading_data")} isError={true}/>
            }
            <Footer/>
        </div>
    );
}