import React, {useEffect, useMemo, useState} from "react";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {formatDateTime} from "../../../helpers/FormatHelper";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {StethoscopeDetailsTable} from "../../Widgets/StethoscopeDetailsTable/StethoscopeDetailsTable";
import {StethoscopeViewer} from "../../Widgets/StethoscopeViewer/StethoscopeViewer";
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import {Record} from "../../../models/Record";
import {Role} from "../../../models/Role";
import {WaveFile} from "wavefile";
import {useParams} from "react-router";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from "../../../api/ApiHelper";
import {StethoscopeData} from "../../../models/StethoscopeData";
import {selectToken} from "../../../features/account/accountSlice";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import {stethoscopeTestData} from "../../../sts/StethoscopeTest";
import {Conclusion} from "../../Widgets/Conclusion/Conclusion";
import {CommentList} from "../../Widgets/CommentList/CommentList";
import {Comment} from "../../../models/Comment";

interface PathParams {
    recordToken: string;
}

export function SharedStethoscopeView(){
    const isSupport = useAccessInfo(Role.Support);
    const wavFiles = useMemo(() => new Map<string, WaveFile>(), []);
    let {recordToken} = useParams<PathParams>();
    const controller = useAbortController();
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();
    const [updateTrigger, setUpdateTrigger] = useState(0);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [data, setData] = useState(null as StethoscopeData | null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [compand, setCompand] = useState(true);
    const [timeScaleIndex, setTimeScaleIndex] = useState(1);
    const wavHandler = (fileId: string, data: Blob) => {
        data.arrayBuffer().then(buffer => {
            const file = new WaveFile();
            file.fromBuffer(new Uint8Array(buffer));
            wavFiles.set(fileId, file);
            setUpdateTrigger(updateTrigger + 1);
        });
    };
    const dataHandler = (data: StethoscopeData) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setActiveTabIndex(0);
        setData(data);
    };
    const recordHandler = (record: Record) => {
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
    };
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
        }
    };
    const fetchWav = (fileId: string) => {
        if (process.env.NODE_ENV === 'development') {
            // For testing
            fetch(`/test/${fileId}`).then(result => result.blob().then(blob => wavHandler(fileId, blob)));
        } else {
            //Main code
            let userToken = token?.token;
            if (userToken && record) {
                ApiHelper.getRawDataWithToken(recordToken, fileId, controller, (blob) => wavHandler(fileId, blob), errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchData = (record: Record) => {
        if (process.env.NODE_ENV === 'development') {
            // For testing
            dataHandler(stethoscopeTestData);
        } else {
            //Main code
            let userToken = token?.token;
            if (userToken && record.stethoscopeStudy) {
                setFetchingState(true);
                ApiHelper.getStethoscopeDataWithToken(recordToken, record.stethoscopeStudy.stethoscopeId, controller, dataHandler, errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecordWithToken(recordToken, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => {
        if (data) {
            const id = data.records[activeTabIndex]?.fileId;
            if (id) {
                if (!wavFiles.has(id)) {
                    fetchWav(id);
                }
            }
        }
    }, [activeTabIndex, data, wavFiles]);     // eslint-disable-line
    useEffect(() => fetchRecord(), [recordToken]);     // eslint-disable-line
    useEffect(() => {
        if (record) {
            fetchData(record);
        }
    }, [record]);     // eslint-disable-line
    const isOk = !hasError && !notFound && !isFetching;
    const activeWav = wavFiles.get(data?.records[activeTabIndex]?.fileId ?? "");
    const tabButtonClickHandler: (index: number) => void = (index) => {
        if (index !== activeTabIndex) {
            setActiveTabIndex(index);
        }
    };
    const buttons = data?.records.map((r, i) => <div key={`b-${i}`}
                                                     className={`tab-round-button ${i === activeTabIndex ? "tab-round-button-selected" : ""}`}
                                                     onClick={() => tabButtonClickHandler(i)}>{i + 1}</div>);
    return (
        <div>
            <Breadcrumbs
                data={new Map([[record?.id ?? "_", record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && data) &&
            <StethoscopeDetailsTable record={record} data={data} isSupport={isSupport}/>
            }
            {(isOk && record && data) &&
            <div className="data-list">
                {buttons &&
                <div className="d-flex justify-content-center mt-4 mb-2">
                    {buttons}
                </div>
                }
                <div
                    className="d-flex justify-content-center data-list-title my-2">{data.records[activeTabIndex].locationDescription}</div>
                {activeWav !== undefined ?
                    <StethoscopeViewer record={record} stethoscopeRecord={data.records[activeTabIndex]}
                                       wavData={activeWav} compand={compand} timeScaleIndex={timeScaleIndex}
                                       compandChangeHandler={(state) => setCompand(state)}
                                       timeScaleIndexChangeHandler={(index) => setTimeScaleIndex(index)}/> :
                    <AsyncIndicator/>
                }
            </div>
            }
            {(isOk && record) && <Conclusion initialValue={record.stethoscopeStudy.stethoscopeConclusion ?? ""} cachedValue={record.stethoscopeStudy.stethoscopeConclusion ?? ""}/>}
        </div>
    );
}