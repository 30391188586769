import {useEffect, useState} from "react";
import {selectToken} from "../../../features/account/accountSlice";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import PagedData from "../../../models/PagedData";
import {Record} from "../../../models/Record";
import * as ApiHelper from '../../../api/ApiHelper';
import {SORT_BY_DATE, SORT_BY_NAME} from "../../../models/SortType";
import React from "react";
import handleErrors from "../../../helpers/ErrorHandler";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {FetchError} from "../FetchError/FetchError";
import {RecordsLong} from "../RecordsLong/RecordsLong";
import {SearchInput} from "../SearchInput/SearchInput";
import RecordsPlaceholder from "../RecordsPlaceholder/RecordsPlaceholder";
import {ListPagination} from "../Pagination/ListPagination";
import {SmallDoubleButton, SmallDoubleButtonSide} from "../SmallDoubleButton/SmallDoubleButton";
import {useTranslation} from "react-i18next";
import {AccessType} from "../../../models/AccessType";

interface RecordsListProps {
    showTitle?: boolean;
    pageSize: number;
    userId: string | null;
    accessType: AccessType;
    patientId: string | null;
    recordClickHandler: (id: string) => void;
    contextMenuHandler?: (id: string, anchorX: number, anchorY: number) => void;
    hasControls?: boolean;
}

export const RecordsList: React.FC<RecordsListProps> = ({
                                                            showTitle = false,
                                                            pageSize,
                                                            userId,
                                                            accessType,
                                                            patientId,
                                                            recordClickHandler,
                                                            contextMenuHandler,
                                                            hasControls = true
                                                        }: RecordsListProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const controller = useAbortController();
    const token = useAppSelector(selectToken);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filter, setFilter] = useState("");
    const [order, setOrder] = useState(SORT_BY_DATE);
    const [records, setRecords] = useState([] as Record[]);
    const handleFilterChange = (filter: string) => {
        setFilter(filter);
        fetchRecords(currentPage, filter, order);
    };
    const handleOrderChange = (order: string) => {
        setOrder(order);
        fetchRecords(currentPage, filter, order);
    }
    const paginationClickHandler = (page: number) => {
        setCurrentPage(page);
    };
    const recordsHandler = (pagedData: PagedData<Record>) => {
        setFetchingState(false);
        setErrorState(false);
        setRecords(pagedData.data);
        setCurrentPage(pagedData.page);
        setTotalPages(pagedData.totalPages);
        setTotalRecords(pagedData.totalRecords);
    };
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
        }
    };
    const fetchRecords = (page: number, filter: string, order: string) => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecords(userToken, userId, accessType, patientId, filter !== "" ? filter : null, order, page, pageSize, controller, recordsHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecords(currentPage, filter, order), [currentPage, filter, order]);     // eslint-disable-line
    if (!isFetching && hasError) {
        return (
            <FetchError onRetry={() => fetchRecords(currentPage, filter, order)}/>
        );
    }
    const recordsComponents = records.map(record => <RecordsLong key={record.id} record={record}
                                                                 onClick={() => recordClickHandler(record.id)}
                                                                 onContextMenuHandler={(x, y) => contextMenuHandler ? contextMenuHandler(record.id, x, y) : null}/>)
    const hasData = recordsComponents.length > 0;
    return (
        <div className="data-list-container">
            <div className="data-list">
                {showTitle &&
                <div className="data-list-title">{t("total_records")}: <span>{totalRecords}</span></div>
                }
                {hasControls &&
                <div className="d-flex justify-content-between align-items-center">
                    <SearchInput text={filter} onChange={handleFilterChange}/>
                    <SmallDoubleButton leftButtonText={t("name")} rightButtonText={t("date")}
                                       activeButton={order === SORT_BY_DATE ? SmallDoubleButtonSide.Right : SmallDoubleButtonSide.Left}
                                       onSwitch={(side) => handleOrderChange(side === SmallDoubleButtonSide.Right ? SORT_BY_DATE : SORT_BY_NAME)}/>
                </div>
                }
                {isFetching && <AsyncIndicator/>}
                {!isFetching && (hasData ? (
                    <table>
                        <thead>
                        <tr className="table-header">
                            <th>{t("name")}</th>
                            <th>{t("date")}</th>
                            <th colSpan={5}>{t("studies")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {recordsComponents}
                        </tbody>
                    </table>
                ) : (
                    <RecordsPlaceholder className="my-4"/>
                ))}
            </div>
            {!isFetching && totalPages > 1 &&
            <ListPagination page={currentPage} totalPages={totalPages} clickHandler={paginationClickHandler}/>}
        </div>
    );
}