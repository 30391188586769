
export const ACCOUNT_ROUTE = "/account";
export const SIGN_IN_ROUTE = ACCOUNT_ROUTE + "/signin";
export const SIGN_UP_ROUTE = ACCOUNT_ROUTE + "/signup";
export const RESTORE_ROUTE = ACCOUNT_ROUTE + "/restore";
export const RESET_ROUTE = ACCOUNT_ROUTE + "/reset";
export const BLOCKED_ROUTE = ACCOUNT_ROUTE + "/blocked";
export const NOT_CONFIRMED_ROUTE = ACCOUNT_ROUTE + "/notconfirmed";
export const SHARE_ROUTE = "/share";
export const HOME_ROUTE = "/";
export const MY_PATIENTS_ROUTE = "/mypatients";
export const MY_RECORDS_ROUTE = "/myrecords";
export const SHARED_PATIENTS_ROUTE = "/shared-patients";
export const SHARED_RECORDS_ROUTE = "/shared-records";
export const PATIENTS_ROUTE = "/patients";
export const RECORDS_ROUTE = "/records";
export const USERS_ROUTE = "/users";
export const DASHBOARD_ROUTE = "/dashboard";
export const PROFILE_ROUTE = "/profile";
export const SETTINGS_ROUTE = "/settings";
export const ACCESS_CONTROL_ROUTE = `${SETTINGS_ROUTE}/access`;
export const NOTIFICATION_SETTINGS_ROUTE = `${SETTINGS_ROUTE}/notifications`;