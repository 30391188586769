import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router";
import {Redirect, useLocation} from "react-router-dom";
import {selectAccountStatus, selectToken, selectUserInfo, updateUserInfo} from "../../features/account/accountSlice";
import {useAbortController, useAppDispatch, useAppSelector} from "../../hooks";
import * as AppRoutes from '../../routes';
import * as ApiHelper from '../../api/ApiHelper';
import {UserInfo} from "../../models/UserInfo";
import ErrorResponse from "../../models/ErrorResponse";
import {AccountStatus} from "../../models/AccountStatus";
import {Container} from "react-bootstrap";
import Loader from "../Widgets/Loader/Loader";
import Header from "../Widgets/Header/Header";
import Footer from "../Widgets/Footer/Footer";
import MyPatients from "./MyPatients/MyPatients";
import PatientDetails from "./PatientDetails/PatientDetails";
import RecordDetails from "./RecordDetails/RecordDetails";
import MyRecords from "./MyRecords/MyRecords";
import Patients from "./Patients/Patients";
import Records from "./Records/Records";
import Users from "./Users/Users";
import Dashboard from "./Dashboard/Dashboard";
import Profile from "./Profile/Profile";
import handleErrors from "../../helpers/ErrorHandler";
import NotFound from "../Widgets/NotFound/NotFound";
import UserDetails from "./UserDetails/UserDetails";
import EcgView from "./EcgView/EcgView";
import SpiroView from "./SpiroView/SpiroView";
import StethoscopeView from "./StethoscopeView/StethoscopeView";
import {SharedRecordDetails} from "./SharedRecordDetails/SharedRecordDetails";
import {SharedEcgView} from "./SharedEcgView/SharedEcgView";
import {SharedSpiroView} from "./SharedSpiroView/SharedSpiroView";
import {SharedStethoscopeView} from "./SharedStehoscopeView/SharedStethoscopeView";
import {Settings} from "./Settings/Settings";
import {AccessControlOwnData} from "./AccessControlOwnData/AccessControlOwnData";
import SharedRecords from "./SharedRecords/SharedRecords";
import SharedPatients from "./SharedPatients/SharedPatients";
import {AccessType} from "../../models/AccessType";
import {AccessControlSettings} from "./AccessControlSettings/AccessControlSettings";
import {AccessControlSharedData} from "./AccessControlSharedData/AccessControlSharedData";
import NotificationSettings from "./NotificationSettings/NotificationSettings";

function Home() {
    const location = useLocation();
    const controller = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const userInfo = useAppSelector(selectUserInfo);
    const accountStatus = useAppSelector(selectAccountStatus);
    const [isFetching, setFetchingState] = useState(userInfo === null);
    const [hasError, setErrorState] = useState(false);
    const userInfoHandler = (userInfo: UserInfo) => {
        setFetchingState(false);
        setErrorState(false);
        dispatch(updateUserInfo(userInfo));
    };
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
        }
    };
    useEffect(() => {
        if (token) {
            setFetchingState(true);
            ApiHelper.getProfile(token.token, controller, userInfoHandler, errorHandler);
        }
    }, [userInfo == null]);     // eslint-disable-line
    if (token === null) {
        return (
            <Redirect to={AppRoutes.SIGN_IN_ROUTE}/>
        );
    }
    if (accountStatus === AccountStatus.NotConfirmed) {
        return (
            <Redirect to={AppRoutes.NOT_CONFIRMED_ROUTE}/>
        );
    }
    if (accountStatus === AccountStatus.Blocked) {
        return (
            <Redirect to={AppRoutes.BLOCKED_ROUTE}/>
        );
    }
    if (hasError) {
        return (
            <NotFound/>
        );
    }
    if (isFetching) {
        return (
            <Loader/>
        );
    }
    return (
        <Container fluid>
            <Header/>
            <Switch location={location}>
                <Route exact path={AppRoutes.MY_PATIENTS_ROUTE}>
                    <MyPatients/>
                </Route>
                <Route exact path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId`}>
                    <PatientDetails userId={userInfo?.id ?? null} canShare={true} canEdit={true} accessType={AccessType.Owner}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/share`}>
                    <AccessControlOwnData isAppointment={false}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId`}>
                    <RecordDetails canShare={true}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/share`}>
                    <AccessControlOwnData isAppointment={true}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/ecg`}>
                    <EcgView canEditConclusion={true}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/spiro`}>
                    <SpiroView/>
                </Route>
                <Route exact path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope`}>
                    <Redirect to={`${location.pathname}/1`}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope/:tabIndex`}>
                    <StethoscopeView canEditConclusion={true}/>
                </Route>
                <Route exact path={AppRoutes.SHARED_PATIENTS_ROUTE}>
                    <SharedPatients/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId`}>
                    <PatientDetails userId={userInfo?.id ?? null} canShare={false} canEdit={false} accessType={AccessType.Shared}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/share`}>
                    <AccessControlSharedData isAppointment={false}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId`}>
                    <RecordDetails canShare={false}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/ecg`}>
                    <EcgView canEditConclusion={false}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/spiro`}>
                    <SpiroView/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope`}>
                    <Redirect to={`${location.pathname}/1`}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope/:tabIndex`}>
                    <StethoscopeView canEditConclusion={false}/>
                </Route>
                <Route exact path={AppRoutes.MY_RECORDS_ROUTE}>
                    <MyRecords/>
                </Route>
                <Route exact path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId`}>
                    <RecordDetails canShare={true}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/share`}>
                    <AccessControlOwnData isAppointment={true}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/ecg`}>
                    <EcgView canEditConclusion={true}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/spiro`}>
                    <SpiroView/>
                </Route>
                <Route exact path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/stethoscope`}>
                    <Redirect to={`${location.pathname}/1`}/>
                </Route>
                <Route exact path={`${AppRoutes.MY_RECORDS_ROUTE}/:recordId/stethoscope/:tabIndex`}>
                    <StethoscopeView canEditConclusion={true}/>
                </Route>
                <Route exact path={AppRoutes.SHARED_RECORDS_ROUTE}>
                    <SharedRecords/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId`}>
                    <RecordDetails canShare={false}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/share`}>
                    <AccessControlSharedData isAppointment={true}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/ecg`}>
                    <EcgView canEditConclusion={false}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/spiro`}>
                    <SpiroView/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/stethoscope`}>
                    <Redirect to={`${location.pathname}/1`}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/stethoscope/:tabIndex`}>
                    <StethoscopeView canEditConclusion={false}/>
                </Route>

                <Route exact path={AppRoutes.PATIENTS_ROUTE}>
                    <Patients/>
                </Route>
                <Route exact path={`${AppRoutes.PATIENTS_ROUTE}/:patientId`}>
                    <PatientDetails userId={null} canShare={false} canEdit={true} accessType={AccessType.All}/>
                </Route>
                <Route exact path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId`}>
                    <RecordDetails canShare={false}/>
                </Route>
                <Route exact path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/ecg`}>
                    <EcgView canEditConclusion={false}/>
                </Route>
                <Route exact path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/spiro`}>
                    <SpiroView/>
                </Route>
                <Route exact path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/stethoscope`}>
                    <Redirect to={`${location.pathname}/1`}/>
                </Route>
                <Route exact path={`${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/stethoscope/:tabIndex`}>
                    <StethoscopeView canEditConclusion={false}/>
                </Route>
                <Route exact path={AppRoutes.RECORDS_ROUTE}>
                    <Records/>
                </Route>
                <Route exact path={`${AppRoutes.RECORDS_ROUTE}/:recordId`}>
                    <RecordDetails canShare={false}/>
                </Route>
                <Route exact path={`${AppRoutes.RECORDS_ROUTE}/:recordId/ecg`}>
                    <EcgView canEditConclusion={false}/>
                </Route>
                <Route exact path={`${AppRoutes.RECORDS_ROUTE}/:recordId/spiro`}>
                    <SpiroView/>
                </Route>
                <Route exact path={`${AppRoutes.RECORDS_ROUTE}/:recordId/stethoscope`}>
                    <Redirect to={`${location.pathname}/1`}/>
                </Route>
                <Route exact path={`${AppRoutes.RECORDS_ROUTE}/:recordId/stethoscope/:tabIndex`}>
                    <StethoscopeView canEditConclusion={false}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARE_ROUTE}/:recordToken`}>
                    <SharedRecordDetails/>
                </Route>
                <Route exact path={`${AppRoutes.SHARE_ROUTE}/:recordToken/ecg`}>
                    <SharedEcgView/>
                </Route>
                <Route exact path={`${AppRoutes.SHARE_ROUTE}/:recordToken/spiro`}>
                    <SharedSpiroView/>
                </Route>
                <Route exact path={`${AppRoutes.SHARE_ROUTE}/:recordToken/stethoscope`}>
                    <Redirect to={`${location.pathname}/1`}/>
                </Route>
                <Route exact path={`${AppRoutes.SHARE_ROUTE}/:recordToken/stethoscope/:tabIndex`}>
                    <SharedStethoscopeView/>
                </Route>
                <Route exact path={AppRoutes.USERS_ROUTE}>
                    <Users/>
                </Route>
                <Route exact path={`${AppRoutes.USERS_ROUTE}/:userId`}>
                    <UserDetails/>
                </Route>
                <Route exact path={AppRoutes.DASHBOARD_ROUTE}>
                    <Dashboard/>
                </Route>
                <Route exact path={AppRoutes.PROFILE_ROUTE}>
                    <Profile/>
                </Route>
                <Route exact path={AppRoutes.SETTINGS_ROUTE}>
                    <Settings/>
                </Route>
                <Route exact path={AppRoutes.ACCESS_CONTROL_ROUTE}>
                    <AccessControlSettings/>
                </Route>
                <Route exact path={AppRoutes.NOTIFICATION_SETTINGS_ROUTE}>
                    <NotificationSettings/>
                </Route>
                <Route exact path={AppRoutes.HOME_ROUTE}>
                    <Redirect to={AppRoutes.DASHBOARD_ROUTE}/>
                </Route>
                <Route>
                    <NotFound/>
                </Route>
            </Switch>
            <Footer/>
        </Container>
    );
}

export default Home;