import React from 'react';
import Home from './components/Home/Home';
import * as AppRoutes from './routes';
import {Route, Switch} from 'react-router-dom';
import Account from './components/Account/Account';

function App() {
    return (
        <Switch>
            <Route path={AppRoutes.ACCOUNT_ROUTE}>
                <Account />
            </Route>
            <Route>
                <Home />
            </Route>            
        </Switch>
    );
}

export default App;
