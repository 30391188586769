import {Comment} from "../../../models/Comment";
import React, {useMemo, useRef, useState} from "react";
import {formatUtcTimestamp} from "../../../helpers/FormatHelper";
import {CommentView} from "../CommentView/CommentView";
import {Button} from "../Button/Button";
import {useTranslation} from "react-i18next";
import {TextBox} from "../InputBox/TextBox";
import {Divider} from "@material-ui/core";
import './CommentList.css';
import {ReferenceCommentView} from "../CommentView/ReferenceCommentView";
import CancelIconSvg from "../../../assets/svg/CancelIcon";

interface CommentListProps {
    comments: Array<Comment>;
    onPostComment: (text: string, referenceId?: string) => void;
    onMakeConclusion?: (text: string) => void;
    onDeleteComment: (id: string) => void;
}

interface CommentModel {
    id: string;
    user: string;
    email: string;
    text: string;
    timestamp: string;
    reference?: CommentModel;
}

export const CommentList: React.FC<CommentListProps> = ({
                                                            comments,
                                                            onPostComment,
                                                            onMakeConclusion,
                                                            onDeleteComment
                                                        }: CommentListProps) => {
    const {t} = useTranslation();
    const commentInputRef = useRef<HTMLDivElement>(null);
    const [commentText, setCommentText] = useState("");
    const [replyComment, setReplyComment] = useState(null as CommentModel | null);
    const [hasCommentTextError, setHasCommentTextError] = useState(false);
    const commentModels = useMemo(() => {
        return comments.map(c => {
            let reference;
            if (c.replyId) {
                const referenceComment = comments.find(i => i.id === c.replyId);
                if (referenceComment) {
                    reference = {
                        id: referenceComment.id,
                        user: referenceComment.userName,
                        email: referenceComment.userEmail,
                        text: referenceComment.text,
                        timestamp: formatUtcTimestamp(referenceComment.dateTime)
                    } as CommentModel;
                }
            }
            return {
                id: c.id,
                user: c.userName,
                email: c.userEmail,
                text: c.text,
                timestamp: formatUtcTimestamp(c.dateTime),
                reference: reference
            } as CommentModel;
        });
    }, [comments]);
    const onUserClick = (email: string) => {
        window.open(`mailto:${email}`, '_blank');
    };
    const onReplyClick = (comment: CommentModel) => {
        setReplyComment(comment);
        commentInputRef.current?.scrollIntoView();
        const elementsByTagName = document.getElementsByTagName("textarea");
        if (elementsByTagName.length > 0) {
            elementsByTagName[0].focus();
        }
    };
    const onDeleteClick = (id: string) => {
        onDeleteComment(id);
    };
    const onReferenceClick = (referenceId: string) => {
        const elementById = document.getElementById(referenceId);
        elementById?.scrollIntoView();
    };
    const addCommentClick = () => {
        const text = commentText;
        if (text.trim() === "") {
            setHasCommentTextError(true);
        } else {
            setHasCommentTextError(false);
            onPostComment(text, replyComment?.id);
            setCommentText("");
            setReplyComment(null);
        }
    };
    const textBoxChildren = replyComment ? (
        <div className="d-flex flex-row justify-content-between align-items-center mt-2">
            <div className="flex-grow-1">
                <ReferenceCommentView id={replyComment.id} user={replyComment.user}
                                      text={replyComment.text}
                                      timestamp={replyComment.timestamp} clip={false}/>
            </div>
            <span className="mx-2 reference-comment-remove-icon" onClick={() => setReplyComment(null)}><CancelIconSvg/></span>
        </div>
    ) : null;
    return (
        <div className="d-flex flex-column">
            <div ref={commentInputRef} className="d-flex flex-column align-items-stretch">
                <TextBox className="mt-4 mb-2 input-box-textarea input-box-comment" initialValue={commentText}
                         title={t("write_your_note")}
                         onChange={setCommentText} hasError={hasCommentTextError}
                         children={textBoxChildren}/>
                <Button text={t("add_comment")} className="mt-2 mb-4 align-self-end" danger={false}
                        onClick={addCommentClick}/>
            </div>
            <span className="comments-title disable-select mx-2">{`${t("comments")}: ${commentModels.length}`}</span>
            <Divider/>
            {commentModels.map(c => <CommentView key={c.id} id={c.id} user={`${c.user} (${c.email})`} text={c.text}
                                                 timestamp={c.timestamp}
                                                 referenceComment={c.reference ? {
                                                     id: c.reference.id,
                                                     user: `${c.reference.user} (${c.reference.email})`,
                                                     text: c.reference.text,
                                                     timestamp: c.reference.timestamp
                                                 } : undefined}
                                                 onUserClick={() => onUserClick(c.email)}
                                                 onReplyClick={() => onReplyClick(c)}
                                                 onDeleteClick={() => onDeleteClick(c.id)}
                                                 onReferenceClick={() => onReferenceClick(c.reference?.id ?? "")}
                                                 onConclusionClick={onMakeConclusion}/>)}
        </div>
    );
}