import React, {useEffect, useState} from "react";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {Button} from "../Button/Button";
import {InputBox} from "../InputBox/InputBox";
import * as ApiHelper from "../../../api/ApiHelper";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import {useAppDispatch} from "../../../hooks";
import {useTranslation} from "react-i18next";
import SvgEnvelopeIcon from "../../../assets/svg/EnvelopeIcon";
import {UserNotFound} from "../../../api/ErrorCodes";

interface DialogGrantAssociatedUserAccessProps {
    token: string;
    controller: AbortController;
    closeHandler: (refresh: boolean) => void;
}

export const DialogGrantAssociatedUserAccess: React.FC<DialogGrantAssociatedUserAccessProps> = ({
                                                                                                    token,
                                                                                                    controller,
                                                                                                    closeHandler
                                                                                                }: DialogGrantAssociatedUserAccessProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState('');
    const [badInput, setBadInput] = useState(false);
    const handleEmailChange = (value: string) => setEmail(value);
    const [isFetching, setFetchingState] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null as string | null);
    const [dialogTop, setDialogTop] = useState(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    const scrollHandler = () => {
        setDialogTop(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    };
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    });
    const keyDownHandler = (e : KeyboardEvent) => {
        if (e.key === "Enter"){
            handleGrantButtonClick();
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        }
    });
    const grantHandler = () => {
        setFetchingState(false);
        setErrorMessage(null);
        closeHandler(true);
    };
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === UserNotFound) {
                setBadInput(true);
                setErrorMessage(t("user_with_email_not_found"));
            } else {
                setBadInput(false);
                setErrorMessage(t("connection_problem"));
            }
        }
    };
    const handleGrantButtonClick = () => {
        if (email.trim() === '') {
            setErrorMessage(t("enter_email"));
            setBadInput(true);
            return;
        }
        setBadInput(false);
        setErrorMessage(null);
        setFetchingState(true);
        ApiHelper.addAssociatedUser(token, email, controller, grantHandler, errorHandler);
    };
    const documentHeight = document.documentElement.offsetHeight;
    return (
        <div className="dialog-container" style={{height: documentHeight}}>
            {isFetching ? (
                <div className="window-modal" style={{marginTop: dialogTop}}>
                    <AsyncIndicator/>
                </div>
            ) : (
                <div className="window-modal" style={{marginTop: dialogTop}}>
                    <div
                        className="window-modal-title disable-select pb-4 d-flex justify-content-center">{t("grant_access_to_user")}</div>
                    <div className="window-modal-error d-flex justify-content-center align-items-center my-2">
                        {errorMessage ? errorMessage : ""}
                    </div>
                    <InputBox className="my-2" inputType="email" title={t("email")} initialValue={email}
                              hasError={badInput} onChange={handleEmailChange} icon={<SvgEnvelopeIcon/>}/>
                    <div className="d-flex justify-content-around">
                        <Button className="mt-4" text={t("ok")} danger={true} onClick={handleGrantButtonClick}/>
                        <Button className="mt-4" text={t("cancel")} onClick={() => closeHandler(false)}/>
                    </div>
                </div>)
            }
        </div>
    );
}