import moment from "moment";
import './Footer.css';
import {useTranslation} from "react-i18next";
import {APP_VERSION} from "../../../settings";

const Footer = () => {
    const {t} = useTranslation();
    const year = moment().format("YYYY");
    return (
        <div className="footer-container">
            <div className="flex-grow-1"/>
            <div className="d-flex justify-content-center footer-links">
                <a target="_blank" rel="noopener noreferrer"  href="https://binora.com.ua">{t("about_us")}</a>
                <a target="_blank" rel="noopener noreferrer" href="https://binora.com.ua/contact">{t("contacts")}</a>
                <a target="_blank" rel="noopener noreferrer" href="https://binora.com.ua">{t("terms_and_conditions")}</a>
                <a target="_blank" rel="noopener noreferrer" href="https://binora.com.ua/contact">{t("support")}</a>
            </div>
            <div className="d-flex justify-content-center footer-copyrights">{`Copyright © ${year} Binora ${APP_VERSION}. All rights reserved.`}</div>
        </div>
        );
}

export default Footer;