import React from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import SvgFullLogoDark from "../../../assets/svg/FullLogoDark";
import SvgFullLogoLight from "../../../assets/svg/FullLogoLight";

export const HeaderLogo = () => {
    const { themes, currentTheme } = useThemeSwitcher();
    let logo;
    if (currentTheme === themes.dark) {
        logo = <SvgFullLogoDark />;
    }
    if (currentTheme === themes.light || !logo) {
        logo = <SvgFullLogoLight />;
    }
    return (
        <div style={{ width: "100%", height: "48px" }}>
            {logo}
        </div>
    );
}

export default HeaderLogo;