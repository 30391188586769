import React, {useEffect, useMemo, useState} from "react";
import {Group, Layer, Line, Rect, Stage, Text} from "react-konva";
import {EcgData} from "../../../models/EcgData";
import {calculateLeads, getLeadName, getNumberOfLeads} from "../../../ecg/EcgHelper";
import {getStyledColor} from "../../../helpers/DrawHelper";
import {filterEcgData} from "../../../ecg/EcgFilter";
import {PX_PER_MM} from "./EcgViewer";
import {PlotPointsBuilder} from "../../../helpers/PlotPointsBuilder";
import {useTranslation} from "react-i18next";
import {Vector2d} from "konva/types/types";

const MM_PER_LEAD = 20;

const MAJOR_LINE_WIDTH_PX = 1.2;
const MINOR_LINE_WIDTH_PX = 1;
const MILLI_VOLT_LINE_WIDTH_PX = 2;
const MILLI_VOLT_TEXT_SIZE_MM = 2.5;
const SCALE_DATA_TEXT_SIZE_MM = 3.5;
const LEGEND_OPACITY = 0.75;
const DATA_LINE_WIDTH = 1.5;

interface Point {
    x: number;
    y: number;
}

interface Props {
    width: number;
    numberOfSamples: number;
    firstSample: number;
    lastSample: number;
    ecgData: EcgData;
    mvScale: number;
    timeScale: number;
    lpf: boolean;
    hpf: boolean;
    showSaveRegion: boolean;
    saveRegionWidth: number | null;
    savePositionChangeListener: (position: number) => void;
    moveRegionLeft: boolean;
    moveRegionRight: boolean;
}

const REGION_MOVE_INTERVAL = 100;
const REGION_MOVE_STEP_MULTIPLIER_UPDATE_PERIOD = 30;

export const EcgMonitor: React.FC<Props> = ({
                                                width,
                                                numberOfSamples,
                                                firstSample,
                                                lastSample,
                                                ecgData,
                                                mvScale,
                                                timeScale,
                                                lpf,
                                                hpf,
                                                showSaveRegion,
                                                saveRegionWidth,
                                                savePositionChangeListener,
                                                moveRegionLeft,
                                                moveRegionRight
                                            }: Props) => {
    const {t} = useTranslation();
    const [saveRegionPosition, setSaveRegionPosition] = useState(null as number | null);
    const adjustSaveRegionPosition = (position: number) => {
        let adjustedPosition = Math.min(position, numberOfSamples - (saveRegionWidth ?? 0));
        adjustedPosition = Math.max(adjustedPosition, 0);
        return adjustedPosition;
    };
    const getRegionMoveStep = (multiplier: number) => {
        return 1 / timeScale * ecgData.header.sampleRate * multiplier;
    };
    useEffect(() => {
        if (showSaveRegion && saveRegionWidth) {
            let start : number;
            if (saveRegionPosition !== null){
                start = adjustSaveRegionPosition(saveRegionPosition);
            } else {
                const center = firstSample + (lastSample - firstSample) / 2;
                start = adjustSaveRegionPosition(center - saveRegionWidth / 2);
            }
            savePositionChangeListener(start);
            setSaveRegionPosition(start);
        } else {
            setSaveRegionPosition(null);
        }
    }, [showSaveRegion, saveRegionWidth]);  // eslint-disable-line
    useEffect(() => {
        let interval : NodeJS.Timeout | null = null;
        if (moveRegionLeft && saveRegionPosition){
            let count = 0;
            let multiplier = 1;
            let position = saveRegionPosition;
            position = adjustSaveRegionPosition(position - getRegionMoveStep(multiplier));
            savePositionChangeListener(position);
            setSaveRegionPosition(position);
            interval = setInterval(() => {
                if (count > REGION_MOVE_STEP_MULTIPLIER_UPDATE_PERIOD){
                    count = 0;
                    multiplier *= 2;
                } else {
                    count++;
                }
                position = adjustSaveRegionPosition(position - getRegionMoveStep(multiplier));
                savePositionChangeListener(position);
                setSaveRegionPosition(position);
            }, REGION_MOVE_INTERVAL);
        }
        return () => {
            if (interval){
                clearInterval(interval);
            }
        };
    }, [moveRegionLeft]);  // eslint-disable-line
    useEffect(() => {
        let interval : NodeJS.Timeout | null = null;
        if (moveRegionRight && saveRegionPosition){
            let count = 0;
            let multiplier = 1;
            let position = saveRegionPosition;
            position = adjustSaveRegionPosition(position + getRegionMoveStep(multiplier));
            savePositionChangeListener(position);
            setSaveRegionPosition(position);
            interval = setInterval(() => {
                if (count > REGION_MOVE_STEP_MULTIPLIER_UPDATE_PERIOD){
                    count = 0;
                    multiplier *= 2;
                } else {
                    count++;
                }
                position = adjustSaveRegionPosition(position + getRegionMoveStep(multiplier));
                savePositionChangeListener(position);
                setSaveRegionPosition(position);
            }, REGION_MOVE_INTERVAL);
        }
        return () => {
            if (interval){
                clearInterval(interval);
            }
        };
    }, [moveRegionRight]); // eslint-disable-line
    const numberOfLeads = useMemo(() => getNumberOfLeads(ecgData.header.adcChannels), [ecgData.header.adcChannels]); // eslint-disable-line
    const height = useMemo(() => (numberOfLeads ?? 0) * MM_PER_LEAD * PX_PER_MM, [numberOfLeads]); // eslint-disable-line
    const filteredLeadsData = useMemo(() => {
        const filteredData = ecgData.ecg.map(channel => filterEcgData(channel, lpf, hpf));
        const leadsData = new Array<Array<number | null>>();
        for (let i = 0; i < numberOfSamples; i++) {
            const input = new Array<number | null>();
            for (let j = 0; j < filteredData.length; j++) {
                input.push(filteredData[j][i])
            }
            leadsData.push(calculateLeads(input));
        }
        return leadsData;
    }, [numberOfSamples, ecgData, lpf, hpf]); // eslint-disable-line
    const mvScaledData = useMemo(() => {
        const scaleFactor = -1 * mvScale * PX_PER_MM / ecgData.header.adcScale;
        return filteredLeadsData.map(samples => samples.map(sample => (sample ?? 0) * scaleFactor))
    }, [filteredLeadsData, ecgData, mvScale]); // eslint-disable-line
    const timeScaleFactor = useMemo(() => timeScale * PX_PER_MM / ecgData.header.sampleRate, [timeScale, ecgData]); // eslint-disable-line
    const timeScaledData = useMemo(() => {
        const points = new Array<Array<Point>>();
        for (let i = 0; i < (numberOfLeads ?? 0); i++) {
            const builder = new PlotPointsBuilder();
            for (let j = 0; j < numberOfSamples; j++) {
                builder.addData(j * timeScaleFactor, mvScaledData[j][i]);
            }
            points.push(builder.build());
        }
        return points;
    }, [mvScaledData, timeScaleFactor, numberOfLeads, numberOfSamples]); // eslint-disable-line
    const drawPoints = useMemo(() => {
        const startX = firstSample * timeScaleFactor;
        const endX = lastSample * timeScaleFactor;
        const points = new Array<Array<number>>();
        for (let i = 0; i < (numberOfLeads ?? 0); i++) {
            const channelPoints = new Array<number>();
            const offsetY = (i + 0.5) * MM_PER_LEAD * PX_PER_MM;
            const channel = timeScaledData[i];
            let isFirst = true;
            for (let j = 0; j < channel.length; j++) {
                const point = channel[j];
                if (point.x >= startX && point.x <= endX) {
                    if (isFirst) {
                        if (j > 0) {
                            const previousPoint = channel[j - 1];
                            channelPoints.push(previousPoint.x - startX, previousPoint.y + offsetY)
                        }
                        isFirst = false;
                    }
                    channelPoints.push(point.x - startX, point.y + offsetY)
                }
                if (point.x > endX) {
                    channelPoints.push(point.x - startX, point.y + offsetY)
                    break;
                }
            }
            points.push(channelPoints);
        }
        return points;
    }, [firstSample, lastSample, timeScaledData, timeScaleFactor, numberOfLeads]); // eslint-disable-line
    let base = null;
    let border = null;
    let majorGridLines = null;
    let minorGridLines = null;
    let scaleInfo = null;
    let milliVolts = null;
    let leads = null;
    let saveRegion = null;
    if (numberOfLeads && width > 0 && height > 0) {
        const backgroundColor = getStyledColor("--ecg-background");
        const gridLineColor = getStyledColor("--ecg-grid-line");
        const contentColors = [getStyledColor("--ecg-content-1"), getStyledColor("--ecg-content-2"), getStyledColor("--ecg-content-3")];
        const scaleDataColor = getStyledColor("--primary-text");
        const saveRegionColor = getStyledColor("--active-secondary-background");
        base = <Rect width={width} height={height} x={0} y={0} strokeEnabled={false} fill={backgroundColor}
                     fillEnabled={true}/>
        border =
            <Rect width={width} height={height} x={0} y={0} strokeWidth={MAJOR_LINE_WIDTH_PX} stroke={gridLineColor}/>
        majorGridLines = [];
        minorGridLines = [];
        const gridStep = 5 * PX_PER_MM;
        let iX = 1;
        for (let x = gridStep; x < width; x += gridStep) {
            if (iX % 2 === 0) {
                majorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iX++;
        }
        let iY = 1;
        for (let y = gridStep; y < height; y += gridStep) {
            if (iY % 2 === 0) {
                majorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iY++;
        }
        const scaleData = t("ecg_scale_data_format", {mvScale: mvScale.toFixed(1), timeScale: timeScale.toFixed(1)});
        const scaleDataTextSize = SCALE_DATA_TEXT_SIZE_MM * PX_PER_MM;
        scaleInfo = <Text x={0} y={height - 0.75 * PX_PER_MM - scaleDataTextSize} fill={scaleDataColor} opacity={0.5}
                          fontStyle={"bold"} fontSize={scaleDataTextSize} width={width - 5 * PX_PER_MM} align={"right"}
                          text={scaleData}/>;
        const mvX1 = 0;
        const mvY1 = 0;
        const mvX2 = 1.5 * PX_PER_MM;
        const mvY2 = -mvScale * PX_PER_MM;
        const mvX3 = 3.5 * PX_PER_MM;
        const mvX4 = 5 * PX_PER_MM;
        milliVolts = [];
        for (let lead = 0; lead < numberOfLeads; lead++) {
            const name = getLeadName(lead);
            const offsetY = (lead + 0.5) * MM_PER_LEAD * PX_PER_MM;
            const color = contentColors[lead % contentColors.length];
            milliVolts.push(
                <Group key={`mvg-${lead}`}>
                    <Line key={`mv-${lead}`}
                          points={[mvX1, mvY1 + offsetY, mvX2, mvY1 + offsetY, mvX2, mvY2 + offsetY, mvX3, mvY2 + offsetY, mvX3, mvY1 + offsetY, mvX4, mvY1 + offsetY]}
                          strokeWidth={MILLI_VOLT_LINE_WIDTH_PX} opacity={LEGEND_OPACITY}
                          stroke={color}/>
                    <Text key={`mvt-${lead}`} x={0} width={5 * PX_PER_MM} y={offsetY + 2 * PX_PER_MM} text={name}
                          fill={color} opacity={LEGEND_OPACITY} fontStyle={"bold"}
                          fontSize={MILLI_VOLT_TEXT_SIZE_MM * PX_PER_MM} align={"center"}/>
                </Group>
            );
        }
        leads = drawPoints.map((points, lead) => <Line key={`l-${lead}`} points={points} strokeWidth={DATA_LINE_WIDTH}
                                                       stroke={contentColors[lead % contentColors.length]}/>);
        const dragBoundFunc = (pos: Vector2d) => {
            const regionStart = adjustSaveRegionPosition(firstSample + pos.x / PX_PER_MM / timeScale * ecgData.header.sampleRate);
            savePositionChangeListener(regionStart);
            setSaveRegionPosition(regionStart);
            const newX = (regionStart - firstSample) / ecgData.header.sampleRate * timeScale * PX_PER_MM;
            return {
                x: newX,
                y: 0
            }
        };
        if (showSaveRegion) {
            let y = 0;
            let h = height;
            let x = 0;
            let w = width;
            if (saveRegionWidth !== null && saveRegionPosition !== null) {
                x = (saveRegionPosition - firstSample) / ecgData.header.sampleRate * timeScale * PX_PER_MM;
                w = saveRegionWidth / ecgData.header.sampleRate * timeScale * PX_PER_MM;
            }
            saveRegion = <Rect x={x} y={y} width={w} height={h} strokeEnabled={false} fill={saveRegionColor} opacity={0.35}
                               fillEnabled={true} draggable={true} dragBoundFunc={dragBoundFunc}/>
        }
    }
    return (
        <Stage width={width} height={height}>
            <Layer>
                {base ?? base}
                {minorGridLines && minorGridLines}
                {majorGridLines && majorGridLines}
                {border && border}
                {milliVolts && milliVolts}
                {leads && leads}
                {scaleInfo && scaleInfo}
                {saveRegion && saveRegion}
            </Layer>
        </Stage>
    );
}