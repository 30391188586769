import React from "react";
import { formatDateTime, formatPatientName } from "../../../helpers/FormatHelper";
import { Record } from '../../../models/Record';
import { StudyIcon, StudyType } from "../StudyIcon/StudyIcon";
import {useTranslation} from "react-i18next";

interface RecordProps {
    record: Record;
    onClick: () => void;
}

export const RecordsShort: React.FC<RecordProps> = ({ record, onClick }: RecordProps) => {
    const {t} = useTranslation();
    return (
        <tr onClick={onClick}>
            <td>{formatPatientName(t, record.patientId === null, record.patientName)}</td>
            <td width="1" align="center">{formatDateTime(record.dateTime)}</td>
            <td width="1" align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.State} isVisible={record.state !== null} />
                </div>
            </td>
            <td width="1" align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.Ecg} isVisible={record.ecgStudy !== null}/>
                </div>
            </td>
            <td width="1" align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.Spiro} isVisible={record.spiroStudy !== null}/>
                </div>
            </td>
            <td width="1" align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.Stethoscope} isVisible={record.stethoscopeStudy !== null}/>
                </div>
            </td>
            <td width="1" align="center">
                <div className="d-flex align-items-center justify-content-center">
                    <StudyIcon type={StudyType.LtEcg} isVisible={record.ltEcgStudy !== null}/>
                </div>
            </td>
        </tr>
    );
}