import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {selectToken} from '../../../features/account/accountSlice';
import handleErrors from '../../../helpers/ErrorHandler';
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from '../../../hooks';
import ErrorResponse from '../../../models/ErrorResponse';
import {Role} from '../../../models/Role';
import {UserDetails as UserDetailsModel} from '../../../models/UserDetails';
import * as ApiHelper from '../../../api/ApiHelper';
import AsyncIndicator from '../../Widgets/AsyncIndicator/AsyncIndicator';
import {FetchError} from '../../Widgets/FetchError/FetchError';
import {UserUpdate} from '../../../models/UserUpdate';
import {InputWrapper} from '../../Widgets/InputWrapper/InputWrapper';
import {ConfirmationStatusDropdown} from '../../Widgets/ConfirmationStatusDropdown/ConfirmationStatusDropdown';
import {formatAccountState, formatBirthdate, formatConfirmationState, formatRole} from '../../../helpers/FormatHelper';
import {Toast} from '../../Widgets/Toast/Toast';
import {Button} from '../../Widgets/Button/Button';
import {RecordsList} from '../../Widgets/RecordsList/RecordsList';
import {DATA_LIST_PAGE_SIZE} from '../../../settings';
import {PatientsList} from '../../Widgets/PatientsList/PatientsList';
import {PATIENTS_ROUTE, RECORDS_ROUTE} from '../../../routes';
import {AccountStatusDropdown} from '../../Widgets/AccountStatusDropdown/AccountStatusDropdown';
import {RoleDropdown} from '../../Widgets/RoleDropdown/RoleDropdown';
import {Avatar} from '@material-ui/core';
import {TextBoxWrapper} from '../../Widgets/InputWrapper/TextBoxWrapper';
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {DialogConfirmation} from '../../Widgets/DialogConfirmation/DialogConfirmation';
import {DialogResetPassword} from '../../Widgets/DialogResetPassword/DialogResetPassword';
import * as ErrorCodes from "../../../api/ErrorCodes";
import NotFound from "../../Widgets/NotFound/NotFound";
import {useTranslation} from "react-i18next";
import {AccessType} from "../../../models/AccessType";

interface PathParams {
    userId: string;
}

export const UserDetails = () => {
    const {t} = useTranslation();
    let {userId} = useParams<PathParams>();
    const controller = useAbortController();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const isAdmin = useAccessInfo(Role.Admin);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [hasSaveError, setSaveErrorState] = useState(false);
    const [keyIndex, setKeyIndex] = useState(0);
    const [user, setUser] = useState(null as UserDetailsModel | null);
    const [editEmailStatus, setEditEmailStatus] = useState(null as boolean | null);
    const [editAccountStatus, setEditAccountStatus] = useState(null as boolean | null);
    const [editRole, setEditRole] = useState(null as Role | null);
    const [editName, setEditName] = useState(null as string | null);
    const [editClinic, setEditClinic] = useState(null as string | null);
    const [editClinicInfo, setEditClinicInfo] = useState(null as string | null);
    const [editInfo, setEditInfo] = useState(null as string | null);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [showPasswordChangeDialog, setShowPasswordChangeDialog] = useState(false);
    const patientClickHandler = (id: string) => {
        history.push(`${PATIENTS_ROUTE}/${id}`);
    };
    const recordClickHandler = (id: string) => {
        history.push(`${RECORDS_ROUTE}/${id}`);
    };
    const initFields = (user: UserDetailsModel) => {
        setKeyIndex(keyIndex + 1);
        setEditEmailStatus(user.emailConfirmed);
        setEditAccountStatus(user.accountActive);
        setEditRole(user.role);
        setEditName(user.name ?? "");
        setEditClinic(user.clinic ?? "");
        setEditClinicInfo(user.clinicDetails ?? "");
        setEditInfo(user.info ?? "")
    };
    const userHandler = (user: UserDetailsModel) => {
        setErrorState(false);
        setNotFoundState(false);
        setSaveErrorState(false);
        setUser(user);
        initFields(user);
        setFetchingState(false);
    };
    const deleteHandler = () => {
        setErrorState(false);
        setNotFoundState(false);
        setSaveErrorState(false);
        setFetchingState(false);
        history.goBack();
    }
    const errorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
        }
        setFetchingState(false);
    };
    const saveErrorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            setSaveErrorState(false);
            setSaveErrorState(true);
        }
        setFetchingState(false);
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getUser(userToken, userId, controller, userHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecord(), [userId]);     // eslint-disable-line
    const nameValidator = (value: string) => {
        if (value.trim() === "") {
            return t("enter_name");
        }
        return null;
    };

    const isChanged = user?.emailConfirmed !== editEmailStatus
        || user?.accountActive !== editAccountStatus
        || user?.role !== editRole
        || user?.name !== editName
        || user?.clinic !== editClinic
        || user?.clinicDetails !== editClinicInfo
        || user?.info !== editInfo;
    const save = () => {
        setFetchingState(true);
        let userToken = token?.token;
        if (userToken && user && editEmailStatus !== null && editAccountStatus !== null && editRole !== null && editName !== null && editClinic !== null && editClinicInfo !== null && editInfo !== null) {
            const newUser: UserUpdate = {
                id: user.id,
                name: editName,
                role: editRole,
                emailConfirmed: editEmailStatus,
                accountActive: editAccountStatus,
                clinic: editClinic,
                clinicDetails: editClinicInfo,
                info: editInfo
            }
            ApiHelper.saveUser(userToken, newUser, controller, userHandler, saveErrorHandler);
        }
    };
    const deleteUser = () => {
        setShowDeleteConfirmationDialog(false);
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.deleteUser(userToken, userId, controller, deleteHandler, saveErrorHandler)
        }
    };
    const isValid = editEmailStatus !== null && editAccountStatus !== null && editRole !== null && editName !== null && editClinic !== null && editClinicInfo !== null && editInfo !== null;
    const isOk = !hasError && !notFound && !isFetching;
    return (
        <div>
            <Breadcrumbs data={new Map([[userId, user?.name ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && user) &&
            <div className="d-flex justify-content-center my-4">
                <div className="details-table">
                    <table>
                        <tbody>
                        <tr>
                            <th>{t("email")}</th>
                            <td>{user.email}</td>
                        </tr>
                        <tr>
                            <th>{t("email_status")}</th>
                            <td>
                                {isAdmin ? <ConfirmationStatusDropdown key={`field-email-status-${keyIndex}`}
                                                                       className="input-dropdown"
                                                                       initialState={editEmailStatus ?? false}
                                                                       disabled={false}
                                                                       onChange={(v) => {
                                                                           setEditEmailStatus(v)
                                                                       }}/> : (formatConfirmationState(t, editEmailStatus ?? false))}
                            </td>
                        </tr>
                        <tr>
                            <th>{t("account_status")}</th>
                            <td>
                                {isAdmin ? <AccountStatusDropdown key={`field-account-status-${keyIndex}`}
                                                                  className="input-dropdown"
                                                                  initialState={editAccountStatus ?? false}
                                                                  disabled={false}
                                                                  onChange={(v) => {
                                                                      setEditAccountStatus(v)
                                                                  }}/> : formatAccountState(t, editAccountStatus ?? false)}
                            </td>
                        </tr>
                        <tr>
                            <th>{t("role")}</th>
                            <td>
                                {isAdmin ? <RoleDropdown key={`field-role-${keyIndex}`} className="input-dropdown" disabled={false}
                                                         initialState={editRole ?? Role.Doctor} onChange={(v) => {
                                    setEditRole(v)
                                }}/> : formatRole(t, editRole ?? Role.Doctor)}
                            </td>
                        </tr>
                        <tr>
                            <th>{t("registration_date")}</th>
                            <td>{formatBirthdate(user.registrationDate)}</td>
                        </tr>
                        <tr>
                            <th>{t("name")}</th>
                            <td>
                                <InputWrapper key={`field-name-${keyIndex}`} className="input-text" type="text" disabled={false}
                                              value={editName ?? ""} onChange={(v) => {
                                    setEditName(v)
                                }} validator={nameValidator}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("clinic")}</th>
                            <td>
                                <InputWrapper key={`field-clinic-${keyIndex}`} className="input-text" type="text" disabled={false}
                                              value={editClinic ?? ""} onChange={(v) => {
                                    setEditClinic(v)
                                }}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("clinic_contact_info")}</th>
                            <td>
                                <TextBoxWrapper key={`field-clinic-info-${keyIndex}`} className="input-text"
                                                value={editClinicInfo ?? ""} onChange={(v) => {
                                    setEditClinicInfo(v)
                                }}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("additional_info")}</th>
                            <td>
                                <TextBoxWrapper key={`field-info-${keyIndex}`} className="input-text"
                                                value={editInfo ?? ""} onChange={(v) => {
                                    setEditInfo(v)
                                }}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("photo")}</th>
                            <td>
                                <Avatar src={user.photoUrl} alt={user.name} sizes="32px"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            }
            {hasSaveError &&
            <Toast text={t("connection_problem")} isError={true}/>
            }
            {(isOk && user) &&
            <div className="d-flex justify-content-center">
                <div className="details-controls d-flex justify-content-center my-2">
                    <div>
                        <Button className="mb-4 mr-2" text={t("save_changes")} enabled={isValid && isChanged}
                                highlighted={true} onClick={() => {
                            save()
                        }}/>
                        {isAdmin && <Button text={t("delete")} className="mt-4 mr-2" danger={true} onClick={() => {
                            setShowDeleteConfirmationDialog(true)
                        }}/>}
                    </div>
                    <div>
                        <Button className="mb-4 ml-2" text={t("discard")} onClick={() => {
                            initFields(user)
                        }}/>
                        {isAdmin && <Button text={t("change_password")} className="mt-4 ml-2" onClick={() => {
                            setShowPasswordChangeDialog(true)
                        }}/>}
                    </div>
                </div>
            </div>
            }
            {(isOk && user) &&
            <div className="my-4">
                <PatientsList showTitle={true} userId={userId} accessType={AccessType.All} pageSize={DATA_LIST_PAGE_SIZE}
                              patientClickHandler={patientClickHandler}/>
            </div>
            }
            {(isOk && user) &&
            <div className="my-4">
                <RecordsList showTitle={true} userId={userId} accessType={AccessType.All} patientId={null} pageSize={DATA_LIST_PAGE_SIZE}
                             recordClickHandler={recordClickHandler}/>
            </div>
            }
            {showDeleteConfirmationDialog &&
            <DialogConfirmation titleText={t("delete_user_title")} messageText={t("delete_user_description")}
                                okButtonText={t("delete")} cancelButtonText={t("cancel")}
                                okButtonClickHandler={deleteUser}
                                cancelButtonClickHandler={() => setShowDeleteConfirmationDialog(false)}/>}
            {showPasswordChangeDialog &&
            <DialogResetPassword token={token?.token ?? ""} userId={userId} controller={controller}
                                 cancelButtonClickHandler={() => setShowPasswordChangeDialog(false)}/>}
        </div>
    );
}
;

export default UserDetails;