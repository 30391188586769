export function getNumberOfLeads(adcChannels: number) {
    switch (adcChannels) {
        case 2:
            return 6;
        case 3:
            return 7;
        case 8:
            return 12;
        default:
            return null;
    }
}

export function getLeadName(idx: number) {
    switch (idx) {
        case 0:
            return "I";
        case 1:
            return "II";
        case 2:
            return "III";
        case 3:
            return "aVR";
        case 4:
            return "aVL";
        case 5:
            return "aVF";
        case 6:
            return "V1";
        case 7:
            return "V2";
        case 8:
            return "V3";
        case 9:
            return "V4";
        case 10:
            return "V5";
        case 11:
            return "V6";
        default:
            return "";
    }
}

export function calculateLeads(channelData: Array<number | null>) : Array<number | null>{
    const out = new Array<number | null>();
    let canCalculateLeads = false;
    if (channelData.length >= 2){
        canCalculateLeads = channelData[0] !== null && channelData[1] !== null
        out.push(channelData[0]);
        out.push(channelData[1]);
        // @ts-ignore
        out.push(canCalculateLeads ? Math.round(channelData[1] - channelData[0]) : null);
    }
    if (channelData.length >= 3){
        // @ts-ignore
        out.push(canCalculateLeads ? Math.round(-(channelData[0] + channelData[1]) / 2) : null);
        // @ts-ignore
        out.push(canCalculateLeads ? Math.round(channelData[0] - channelData[1] / 2) : null);
        // @ts-ignore
        out.push(canCalculateLeads ? Math.round(channelData[1] - channelData[0] / 2) : null);
        out.push(channelData[2]);
    }
    if (channelData.length === 8){
        out.push(channelData[3]);
        out.push(channelData[4]);
        out.push(channelData[5]);
        out.push(channelData[6]);
        out.push(channelData[7]);
    }
    return out;
}