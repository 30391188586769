import {Record} from "../../../models/Record";
import {EcgData} from "../../../models/EcgData";
import {
    formatAgeFull,
    formatDateTime,
    formatGenderFull,
    formatHeight,
    formatPatientName, formatTimestamp, formatWeight
} from "../../../helpers/FormatHelper";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    record: Record;
    isSupport: boolean;
    data: EcgData;
}

export function EcgDetailsTable(props: Props) {
    const {t} = useTranslation();
    return (
        <div className="d-flex justify-content-center my-4">
            <div className="details-table">
                <table>
                    <tbody>
                    <tr>
                        <th>{t("name")}</th>
                        <td>{formatPatientName(t, props.record.patientId === null, props.record.patientName)}</td>
                    </tr>
                    <tr>
                        <th>{t("date")}</th>
                        <td>{formatDateTime(props.record.dateTime)}</td>
                    </tr>
                    <tr>
                        <th>{t("gender")}</th>
                        <td>{formatGenderFull(t, props.record.gender)}</td>
                    </tr>
                    <tr>
                        <th>{t("age")}</th>
                        <td>{formatAgeFull(t, props.record.age)}</td>
                    </tr>
                    <tr>
                        <th>{t("height")}</th>
                        <td>{formatHeight(t, props.record.height, props.record.heightUnits)}</td>
                    </tr>
                    <tr>
                        <th>{t("weight")}</th>
                        <td>{formatWeight(t, props.record.weight, props.record.weightUnits)}</td>
                    </tr>
                    {props.data.ecgCalculation &&
                    <tr>
                        <th>{t("ecg_calc_hr")}</th>
                        <td>{props.data.ecgCalculation.hr}</td>
                    </tr>
                    }
                    {props.data.ecgCalculation &&
                    <tr>
                        <th>{t("ecg_calc_rr")}</th>
                        <td>{props.data.ecgCalculation.rr}</td>
                    </tr>
                    }
                    {props.data.ecgCalculation &&
                    <tr>
                        <th>{t("ecg_calc_qrs")}</th>
                        <td>{props.data.ecgCalculation.qrsDuration}</td>
                    </tr>
                    }
                    {props.data.ecgCalculation &&
                    <tr>
                        <th>{t("ecg_calc_p_pq")}</th>
                        <td>{`${props.data.ecgCalculation.pDuration}/${props.data.ecgCalculation.pqDuration}`}</td>
                    </tr>
                    }
                    {props.data.ecgCalculation &&
                    <tr>
                        <th>{t("ecg_calc_qt_qtc")}</th>
                        <td>{`${props.data.ecgCalculation.qtDuration}/${props.data.ecgCalculation.qtcDuration}`}</td>
                    </tr>
                    }
                    {props.data.ecgCalculation &&
                    <tr>
                        <th>{t("ecg_calc_p_r_t_axes")}</th>
                        <td>{`${props.data.ecgCalculation.pAxis}/${props.data.ecgCalculation.rAxis}/${props.data.ecgCalculation.tAxis}`}</td>
                    </tr>
                    }
                    {props.data.ecgCalculation &&
                    <tr>
                        <th>{t("ecg_calc_pii")}</th>
                        <td>{props.data.ecgCalculation.pIIamplitude}</td>
                    </tr>
                    }
                    {props.data.ecgCalculation &&
                    <tr>
                        <th>{t("ecg_calc_sv1_rv5")}</th>
                        <td>{`${props.data.ecgCalculation.sV1amplitude}/${props.data.ecgCalculation.rV5amplitude}`}</td>
                    </tr>
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("ecg_timestamp")}</th>
                            <td>{formatTimestamp(props.record.ecgStudy.ecgTimestamp)}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("device_name")}</th>
                            <td>{props.data.header.deviceName}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("device_number")}</th>
                            <td>{props.data.header.deviceNumber}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("device_fw_version")}</th>
                            <td>{props.data.header.deviceFwVersion}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("user_device")}</th>
                            <td>{props.data.header.userDeviceId}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("app_version")}</th>
                            <td>{props.data.header.appVersion}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("format_id")}</th>
                            <td>{props.data.header.formatId}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("adc_channels")}</th>
                            <td>{props.data.header.adcChannels}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("adc_scale")}</th>
                            <td>{props.data.header.adcScale}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("sampling_rate")}Sampling rate</th>
                            <td>{props.data.header.sampleRate}</td>
                        </tr>) : null
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}