import React from "react";
import {useHistory} from "react-router-dom";
import {RECORDS_ROUTE} from "../../../routes";
import {DATA_LIST_PAGE_SIZE} from "../../../settings";
import {RecordsList} from "../../Widgets/RecordsList/RecordsList";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {AccessType} from "../../../models/AccessType";

function Records() {
    const history = useHistory();
    const recordClickHandler = (id: string) => {
        history.push(`${RECORDS_ROUTE}/${id}`);
    };
    return (
        <div>
            <Breadcrumbs />
            <RecordsList pageSize={DATA_LIST_PAGE_SIZE} userId={null} accessType={AccessType.All} patientId={null} recordClickHandler={recordClickHandler} />
        </div>
    );
}

export default Records;