import {useTranslation} from "react-i18next";

interface LanguageDropdownProps {
    className?: string;
}

export const LanguageDropdown = ({className}: LanguageDropdownProps) => {
    const {i18n} = useTranslation();
    const handleLanguageChange = (value: string) => {
        i18n.changeLanguage(value).then(() => {
        });
    }
    return (
        <select className={`${className} disable-select`} defaultValue={i18n.language}
                onChange={(e) => handleLanguageChange(e.target.value)}>
            <option value='en'>En</option>
            <option value='ru'>Ru</option>
            <option value='uk'>Uk</option>
        </select>
    );
}

export default LanguageDropdown;