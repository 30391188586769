import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {loadToken, saveToken} from "../../helpers/TokenHelper";
import {AccountStatus} from "../../models/AccountStatus";
import {UserToken} from "../../models/UserToken";
import {RootState} from "../../store";
import {UserInfo} from '../../models/UserInfo';

interface AccountState {
    token: UserToken | null;
    userInfo: UserInfo | null;
    accountStatus: AccountStatus | null;
}

const initialState: AccountState = {
    token: loadToken(),
    userInfo: null,
    accountStatus: null
}


export const accountSlice = createSlice({
    name: "account",
    initialState: initialState,
    reducers: {
        updateToken: (state : AccountState, action: PayloadAction<UserToken>) => {
            saveToken(action.payload);
            state.token = action.payload;
        },        
        updateUserInfo: (state: AccountState, action: PayloadAction<UserInfo>) => {
            state.userInfo = action.payload;
            state.accountStatus = AccountStatus.Active;
        },
        resetUserInfo: (state: AccountState) => {
            state.userInfo = null;
            state.accountStatus = null;
        },
        updateAccountStatus: (state: AccountState, action: PayloadAction<AccountStatus>) => {
            state.accountStatus = action.payload;
        },
        updatePhotoUrl: (state : AccountState, action: PayloadAction<string>) => {
            if (state.userInfo) {
                const newUserInfo: UserInfo = {
                    id: state.userInfo.id,
                    name: state.userInfo.name,
                    email: state.userInfo.email,
                    clinic: state.userInfo.clinic,
                    clinicDetails: state.userInfo.clinicDetails,
                    info: state.userInfo.info,
                    photoUrl: action.payload,
                    isNew: state.userInfo.isNew,
                    role: state.userInfo.role
                }
                state.userInfo = newUserInfo;
            }
        },
        logout: (state: AccountState) => {
            saveToken(null);
            state.token = null;
            state.userInfo = null;
            state.accountStatus = null;
        },
    }
});

export const { updateToken, updateUserInfo, resetUserInfo, updateAccountStatus, updatePhotoUrl, logout } = accountSlice.actions;

export const selectToken = (state: RootState) => state.account.token;
export const selectUserInfo = (state: RootState) => state.account.userInfo;
export const selectAccountStatus = (state: RootState) => state.account.accountStatus;

export default accountSlice.reducer;