import React from "react";
import { Route, Switch } from "react-router-dom";
import Reset from "./Reset/Reset";
import Restore from "./Restore/Restore";
import SignIn from "./SignIn/SignIn";
import * as AppRoutes from "../../routes";
import Blocked from "./Blocked/Blocked";
import NotConfirmed from "./EmailNotConfirmed/NotConfirmed";
import NotFound from "../Widgets/NotFound/NotFound";

function Account() {
    return (
        <Switch>
            <Route exact path={AppRoutes.SIGN_IN_ROUTE}>
                <SignIn />
            </Route>
            <Route exact path={AppRoutes.SIGN_UP_ROUTE}>
                <SignIn />
            </Route>
            <Route exact path={AppRoutes.RESTORE_ROUTE}>
                <Restore />
            </Route>
            <Route exact path={AppRoutes.RESET_ROUTE}>
                <Reset />
            </Route>
            <Route exact path={AppRoutes.BLOCKED_ROUTE}>
                <Blocked />
            </Route>
            <Route exact path={AppRoutes.NOT_CONFIRMED_ROUTE}>
                <NotConfirmed />
            </Route>
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}

export default Account;