import { useEffect, useState } from "react";
import { selectToken, selectUserInfo } from "../../../features/account/accountSlice";
import { useAbortController, useAccessInfo, useAppDispatch, useAppSelector } from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import PagedData from "../../../models/PagedData";
import { Record } from "../../../models/Record";
import { SORT_BY_DATE } from "../../../models/SortType";
import * as ApiHelper from '../../../api/ApiHelper';
import React from "react";
import handleErrors from "../../../helpers/ErrorHandler";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import { FetchError } from "../FetchError/FetchError";
import { RecordsShort } from "../RecordsShort/RecordsShort";
import InfoCard from "../InfoCard/InfoCard";
import RecordsPlaceholder from "../RecordsPlaceholder/RecordsPlaceholder";
import { Role } from '../../../models/Role';
import {useTranslation} from "react-i18next";
import {AccessType} from "../../../models/AccessType";

interface LimitProps {
    limit: number;
    recordClickHandler: (id: string) => void;
    allRecordsClickHandler: () => void;
}
export const RecentRecords: React.FC<LimitProps> = ({ limit, recordClickHandler, allRecordsClickHandler }: LimitProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const controller = useAbortController();
    const token = useAppSelector(selectToken);
    const userInfo = useAppSelector(selectUserInfo);
    const isSupport = useAccessInfo(Role.Support);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [records, setRecords] = useState([] as Record[]);
    const recordsHandler = (pagedData: PagedData<Record>) => {
        setFetchingState(false);
        setErrorState(false);
        setTotalRecords(pagedData.totalRecords);
        setRecords(pagedData.data);
    };
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
        }
    };
    const fetchRecords = () => {
        let userToken = token?.token;
        let userId = isSupport ? null : userInfo?.id;
        if (userToken && userId !== undefined) {
            setFetchingState(true);
            ApiHelper.getRecords(userToken, userId, AccessType.Owner.valueOf(), null, null, SORT_BY_DATE, 1, limit, controller, recordsHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecords(), []);     // eslint-disable-line
    if (isFetching) {
        return (
            <AsyncIndicator />
        );
    }
    if (hasError) {
        return (
            <FetchError onRetry={fetchRecords} />
        );
    }
    const recordComponents = records.map((record) => <RecordsShort key={record.id} record={record} onClick={() => recordClickHandler(record.id)} />);
    const hasData = recordComponents.length > 0;
    return (
        <div className="data-list-container">
            <InfoCard contentClass="records" titleText={t("records")} captionText={t("total_records_format", {count : totalRecords})} buttonText={t("view")} onClick={allRecordsClickHandler} />
            <div className="mt-4 mb-4 data-list">
                {hasData && <div className="title disable-select">{t("recent_records")}</div>}
                {hasData ? (
                    <table>
                        <tbody>
                            {recordComponents}
                        </tbody>
                    </table>
                ) : (
                    <RecordsPlaceholder />
                )}
            </div>
        </div>
    );
}