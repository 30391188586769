import React from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { useAccessInfo } from "../../../hooks";
import { Role } from "../../../models/Role";
import { MY_PATIENTS_ROUTE, MY_RECORDS_ROUTE, PATIENTS_ROUTE, RECORDS_ROUTE, USERS_ROUTE } from "../../../routes";
import { RECENT_LIST_PAGE_SIZE } from "../../../settings";
import { RecentPatients } from "../../Widgets/RecentPatients/RecentPatients";
import { RecentRecords } from "../../Widgets/RecentRecords/RecentRecords";
import { RecentUsers } from "../../Widgets/RecentUsers/RecentUsers";

function Dashboard() {
    const history = useHistory();
    const isSupport = useAccessInfo(Role.Support);
    const allPatientsClickHandler = () => {
        history.push(isSupport ? PATIENTS_ROUTE : MY_PATIENTS_ROUTE);
    };
    const allRecordsClickHandler = () => {
        history.push(isSupport ? RECORDS_ROUTE : MY_RECORDS_ROUTE);
    };
    const allUsersClickHandler = () => {
        history.push(USERS_ROUTE);
    };
    const patientClickHandler = (id: string) => {
        history.push(`${isSupport ? PATIENTS_ROUTE : MY_PATIENTS_ROUTE}/${id}`);
    };
    const recordClickHandler = (id: string) => {
        history.push(`${isSupport ? RECORDS_ROUTE : MY_RECORDS_ROUTE}/${id}`);
    };
    const userClickHandler = (id: string) => {
        history.push(`${USERS_ROUTE}/${id}`);
    };
    return (
        <Row className="justify-content-center">
            <Col lg={6} xl={4}>
                <RecentPatients limit={RECENT_LIST_PAGE_SIZE} patientClickHandler={patientClickHandler} allPatientsClickHandler={allPatientsClickHandler} />
            </Col>
            <Col lg={6} xl={4}>
                <RecentRecords limit={RECENT_LIST_PAGE_SIZE} recordClickHandler={recordClickHandler} allRecordsClickHandler={allRecordsClickHandler} />
            </Col>
            {isSupport &&
                <Col lg={6} xl={4}>
                <RecentUsers limit={RECENT_LIST_PAGE_SIZE} userClickHandler={userClickHandler} allUsersClickHandler={allUsersClickHandler} />
                </Col>
            }
        </Row>
    );
}

export default Dashboard;