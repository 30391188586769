import React from 'react';
import './InputBox.css';
import TextareaAutosize from 'react-textarea-autosize';

interface TextBoxProps {
    className?: string;
    title: string;
    initialValue: string;
    enabled? : boolean;
    hasError?: boolean;
    onChange: (value: string) => void;
    icon?: JSX.Element | null;
    children?: JSX.Element | null;
}

export const TextBox: React.FC<TextBoxProps> = ({ className = "", title, initialValue, enabled = true, hasError = false, onChange, icon = null, children = null}: TextBoxProps) => {
    return (
        <div className={`input-box ${hasError ? "error" : ""} ${className}`}>
            <div className="input-box-container py-2">
                <span className="disable-select">{title}</span>
                {children && children}
                <TextareaAutosize defaultValue={initialValue} onChange={(e : any) => onChange(e.target.value)} disabled={!enabled}/>
            </div>
            <div className="input-box-icon">
                {icon ?? icon}
            </div>
        </div>
    );
}