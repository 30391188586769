import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {selectToken} from "../../../features/account/accountSlice";
import handleErrors from "../../../helpers/ErrorHandler";
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import {EcgData} from "../../../models/EcgData";
import {Record} from '../../../models/Record';
import {Comment} from '../../../models/Comment';
import ErrorResponse from "../../../models/ErrorResponse";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from '../../../api/ApiHelper';
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {EcgViewer} from "../../Widgets/EcgViewer/EcgViewer";
import {ecgTestData} from "../../../ecg/EcgTest";
import {Role} from "../../../models/Role";
import {EcgDetailsTable} from "../../Widgets/EcgDetailsTable/EcgDetailsTable";
import PagedData from "../../../models/PagedData";
import {CommentList} from "../../Widgets/CommentList/CommentList";
import {Conclusion} from "../../Widgets/Conclusion/Conclusion";
import {Toast} from "../../Widgets/Toast/Toast";
import {useTranslation} from "react-i18next";

interface PathParams {
    recordId: string;
}

interface EcgViewProps {
    canEditConclusion: boolean;
}

export const EcgView: React.FC<EcgViewProps> = ({canEditConclusion}: EcgViewProps) => {
    const {t} = useTranslation();
    const isSupport = useAccessInfo(Role.Support);
    let {recordId} = useParams<PathParams>();
    const controller = useAbortController();
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();
    const [isFetching, setFetchingState] = useState(true);
    const [isCommentsFetching, setCommentsFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [comments, setComments] = useState(null as Array<Comment> | null);
    const [initialConclusion, setInitialConclusion] = useState(null as null | string);
    const [cachedConclusion, setCachedConclusion] = useState(null as null | string);
    const [isConclusionFetching, setConclusionFetching] = useState(false);
    const [showConclusionSaveSuccessToast, setShowConclusionSaveSuccessToast] = useState(false);
    const [showConclusionSaveErrorToast, setShowConclusionSaveErrorToast] = useState(false);
    const [data, setData] = useState(null as EcgData | null);
    const dataHandler = (data: EcgData) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setData(data);
    };
    const recordHandler = (record: Record) => {
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        setInitialConclusion(record.ecgStudy.ecgConclusion ?? "");
        setCachedConclusion(record.ecgStudy.ecgConclusion ?? "");
    };
    const commentsHandler = (comments: PagedData<Comment>) => {
        setCommentsFetchingState(false);
        setComments(comments.data);
    }
    const errorHandler = (error: ErrorResponse) => {
        setFetchingState(false);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
        }
    };
    const commentsErrorHandler = (error: ErrorResponse) => {
        setCommentsFetchingState(false);
        setComments(null);
    };
    const fetchData = (record: Record) => {
        if (process.env.NODE_ENV === 'development') {
            //For testing
            dataHandler(ecgTestData);
        } else {
            //Main code
            let userToken = token?.token;
            if (userToken && record.ecgStudy) {
                setFetchingState(true);
                ApiHelper.getEcgData(userToken, record.id, record.ecgStudy.ecgId, controller, dataHandler, errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecord(userToken, recordId, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    const fetchComments = (recordId: string, studyId: string) => {
        let userToken = token?.token;
        if (userToken) {
            setCommentsFetchingState(true);
            ApiHelper.getComments(userToken, recordId, studyId, controller, commentsHandler, commentsErrorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecord(), [recordId]);     // eslint-disable-line
    useEffect(() => {
        if (record) {
            fetchData(record);
            fetchComments(recordId, record.ecgStudy.ecgId);
        }
    }, [record]);       // eslint-disable-line
    const isOk = !hasError && !notFound && !isFetching;
    const postComment = (text: string, referenceId?: string) => {
        let userToken = token?.token;
        if (userToken && record) {
            setCommentsFetchingState(true);
            ApiHelper.saveComment(userToken, recordId, record.ecgStudy.ecgId, text, referenceId ?? null, controller, () => fetchComments(recordId, record.ecgStudy.ecgId), commentsErrorHandler);
        } else {
            setErrorState(true);
        }
    };
    const deleteComment = (id: string) => {
        let userToken = token?.token;
        if (userToken && record) {
            setCommentsFetchingState(true);
            ApiHelper.deleteComment(userToken, id, controller, () => fetchComments(recordId, record.ecgStudy.ecgId), commentsErrorHandler);
        } else {
            setErrorState(true);
        }
    };
    const conclusionSaveHandler = () => {
        setInitialConclusion(cachedConclusion);
        setConclusionFetching(false);
        setShowConclusionSaveErrorToast(false);
        setShowConclusionSaveSuccessToast(false);
        setShowConclusionSaveSuccessToast(true);

    };
    const conclusionSaveErrorHandler = () => {
        setConclusionFetching(false);
        setShowConclusionSaveSuccessToast(false);
        setShowConclusionSaveErrorToast(false);
        setShowConclusionSaveErrorToast(true);
    };
    const saveConclusion = (text: string) => {
        let userToken = token?.token;
        if (userToken && record) {
            setCachedConclusion(text);
            setConclusionFetching(true);
            ApiHelper.saveEcgConclusion(userToken, recordId, text, controller, conclusionSaveHandler, conclusionSaveErrorHandler);

        }
    };
    return (
        <div>
            <Breadcrumbs
                data={new Map([[recordId, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && data) &&
            <EcgDetailsTable record={record} isSupport={isSupport} data={data}/>
            }
            {(isOk && record && data) &&
            <EcgViewer ecgData={data} recordId={record.id} studyId={record.ecgStudy.ecgId}/>
            }
            {(isOk && record) && (isConclusionFetching ? <AsyncIndicator/> :
                <Conclusion initialValue={initialConclusion ?? ""} cachedValue={cachedConclusion ?? ""}
                            saveHandler={canEditConclusion ? saveConclusion : undefined}/>)}
            {(isOk && showConclusionSaveSuccessToast) &&
            <Toast text={t("conclusion_saved")}/>
            }
            {(isOk && showConclusionSaveErrorToast) &&
            <Toast text={t("conclusion_saved_failed")} isError={true}/>
            }
            {isCommentsFetching && <AsyncIndicator/>}
            {!isCommentsFetching && comments &&
            <CommentList comments={comments}
                         onPostComment={postComment}
                         onDeleteComment={deleteComment}
                         onMakeConclusion={canEditConclusion ? saveConclusion : undefined}/>
            }
        </div>
    );
};

export default EcgView;